import moment from "moment-timezone"

import type EventStream from "./Stream"
import type { AnalyticsData } from "./event"

export default class EventDate {
  id: string
  // eslint-disable-next-line
  name: string
  startTime: moment.Moment
  startTimezone: string
  endTime: moment.Moment
  endTimezone: string
  game: string
  venue: string
  offline: 0 | 1
  attendees: number | null
  platforms: EventStream[]
  estimated: 0 | 1
  cancelled: 0 | 1
  hidden: 0 | 1
  ticket: string | null
  analytics?: AnalyticsData
  taiyoroRating?: number

  constructor(
    id,
    name,
    startTime,
    endTime,
    game,
    venue,
    isOffline,
    attendees,
    platforms,
    isEstimated,
    isCancelled,
    isHidden,
    ticket,
    analytics?,
    taiyoroRating?
  ) {
    this.id = id || null
    this.name = name
    this.startTime = startTime
    this.startTimezone = "Asia/Tokyo"
    this.endTime = endTime
    this.endTimezone = "Asia/Tokyo"
    this.game = game
    this.venue = venue
    this.offline = isOffline || 0
    this.attendees = attendees
    this.platforms = platforms || []
    this.estimated = isEstimated || 0
    this.cancelled = isCancelled || 0
    this.hidden = isHidden || 0
    this.ticket = ticket ?? null
    this.analytics = analytics || {}
    this.taiyoroRating = taiyoroRating
  }
}

export const LocalMomentFromUTCDateTime = (dateTimeString, format = undefined): moment.Moment => {
  return moment.utc(dateTimeString, format).tz("Asia/Tokyo").local()
}

export const UTCMomentFromLocalDateTime = (dateTimeString, format = undefined): moment.Moment => {
  return moment.utc(dateTimeString, format)
}
