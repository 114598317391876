import { useContext } from "react"

import { Stack, Tooltip, Typography } from "@mui/material"
import { type GridColumns, type GridCellEditCommitParams, type GridValidRowModel } from "@mui/x-data-grid"
import { EventSearchAutocomplete } from "components/EventSearchAutocomplete"
import { type LJL2025Results, Ljl2025ResultsContext, type LJLMatchResult } from "contexts/ljl-2025-results"
import type Event from "models/Taiyoro/event"
import { useTranslation } from "react-i18next"

import { ImageUploadCard } from "../ImageUploadCard"
import { ReorderableDataGrid } from "./reorderable-data-grid"
import { useOpposingTeamCell } from "./useOpposingTeamCell"

export const IgniteResults = () => {
  const { igniteTeams, modifiedResults, setModifiedResults, loading, teams } =
    useContext(Ljl2025ResultsContext)

  const { t } = useTranslation()

  const { renderCell, renderEditCell } = useOpposingTeamCell()

  const columns: GridColumns<GridValidRowModel> = [
    {
      flex: 1,
      field: "rank",
      sortable: false,
      headerName: t("taiyoro:ljl.tableHeaders.rank"),
      editable: true,
    },
    {
      flex: 1,
      field: "teamId",
      sortable: false,
      headerName: t("taiyoro:ljl.tableHeaders.team"),
      editable: true,
      type: "singleSelect",
      valueOptions: igniteTeams,
      valueFormatter: ({ value }) => teams.find((teamValue) => teamValue.value === value)?.label,
      renderCell: (params) => {
        const teamExtendedName = teams.find((teamValue) => teamValue.value === params.value)?.label
        const teamName = teams.find((team) => team.id === params.value)?.name
        return (
          <Tooltip title={teamExtendedName}>
            <Typography>{teamName}</Typography>
          </Tooltip>
        )
      },
    },
    {
      flex: 1,
      field: "1",
      sortable: false,
      headerName: "1",
      editable: true,
      renderEditCell,
      renderCell,
    },
    {
      flex: 1,
      field: "2",
      sortable: false,
      headerName: "2",
      editable: true,
      renderEditCell,
      renderCell,
    },
    {
      flex: 1,
      field: "3",
      sortable: false,
      headerName: "3",
      editable: true,
      renderEditCell,
      renderCell,
    },
    {
      flex: 1,
      field: "4",
      sortable: false,
      headerName: "4",
      editable: true,
      renderEditCell,
      renderCell,
    },
    {
      flex: 1,
      field: "5",
      sortable: false,
      headerName: "5",
      editable: true,
      renderEditCell,
      renderCell,
    },
    {
      flex: 1,
      field: "6",
      sortable: false,
      headerName: "6",
      editable: true,
      renderEditCell,
      renderCell,
    },
    {
      flex: 1,
      field: "ignitePoints",
      sortable: false,
      headerName: "Ignite Points",
      editable: true,
      type: "string",
    },
    {
      flex: 1,
      field: "knockoutRank",
      sortable: false,
      headerName: "Knockout Rank",
      editable: true,
      type: "string",
    },
    {
      flex: 1,
      field: "knockoutPoints",
      sortable: false,
      headerName: "Knockout Points",
      editable: true,
      type: "string",
    },
    {
      flex: 1,
      field: "championshipPoints",
      sortable: false,
      headerName: "Championship Points",
      editable: true,
      type: "string",
    },
  ]

  const onCellEditCommit = (params: GridCellEditCommitParams) => {
    setModifiedResults((previousModifiedResults) => {
      const updatedModifiedResults = { ...previousModifiedResults }
      const entry = updatedModifiedResults.ignite.tableData.find((entry) => entry.id === params.id)!
      if (typeof params.value === "string") {
        entry[params.field as keyof Omit<LJL2025Results["ignite"]["tableData"][0], 1 | 2 | 3 | 4 | 5 | 6>] =
          params.value
      } else {
        entry[
          params.field as unknown as keyof Pick<
            LJL2025Results["ignite"]["tableData"][0],
            1 | 2 | 3 | 4 | 5 | 6
          >
        ] = params.value as LJLMatchResult
      }

      return updatedModifiedResults
    })
  }

  const handleIgniteOpenStageUpdate = (newImage: string | null) => {
    const patchedResults = { ...modifiedResults }
    patchedResults.ignite.openStageImage = newImage
    setModifiedResults(patchedResults)
  }

  const handleIgniteMainStageUpdate = (newImage: string | null) => {
    const patchedResults = { ...modifiedResults }
    patchedResults.ignite.mainStageImage = newImage
    setModifiedResults(patchedResults)
  }

  const handleIgniteKnockoutStageUpdate = (newImage: string | null) => {
    const patchedResults = { ...modifiedResults }
    patchedResults.ignite.knockoutStageImage = newImage
    setModifiedResults(patchedResults)
  }

  const handleEventIdUpdate = (event: Event | null) => {
    const patchedResults = { ...modifiedResults }
    patchedResults.ignite.eventId = event ? event.id : null
    setModifiedResults(patchedResults)
  }

  return (
    <>
      <EventSearchAutocomplete
        preloadEventId={modifiedResults.ignite.eventId ?? undefined}
        onEventSelected={handleEventIdUpdate}
      />
      <Stack
        gap={2}
        direction="row"
      >
        <ImageUploadCard
          titleText={"Open Stage Image"}
          image={modifiedResults.ignite.openStageImage}
          onChange={handleIgniteOpenStageUpdate}
        />
        <ImageUploadCard
          titleText={"Main Stage Image"}
          image={modifiedResults.ignite.mainStageImage}
          onChange={handleIgniteMainStageUpdate}
        />
        <ImageUploadCard
          titleText={"Knockout Stage Image"}
          image={modifiedResults.ignite.knockoutStageImage}
          onChange={handleIgniteKnockoutStageUpdate}
        />
      </Stack>
      <ReorderableDataGrid
        rows={modifiedResults.ignite.tableData}
        columns={columns}
        disableColumnSelector
        disableColumnMenu
        disableVirtualization
        autoHeight={true}
        getRowHeight={() => "auto"}
        density="comfortable"
        loading={loading}
        rowCount={modifiedResults.ignite.tableData.length}
        onCellEditCommit={onCellEditCommit}
        onTableDataPositionalChange={(modifiedRows: Array<LJL2025Results["ignite"]["tableData"][0]>) => {
          setModifiedResults((previousModifiedResults) => {
            const updatedModifiedResults = { ...previousModifiedResults }
            updatedModifiedResults.ignite.tableData = [...modifiedRows]
            return { ...updatedModifiedResults }
          })
        }}
        hideFooter
      />
    </>
  )
}
