import { Box, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface Props {
  value: number | ''
  updateValue: (value: number | '') => void
  updateStatus?: () => void
}

export const RewardControl = ({ value, updateValue, updateStatus }: Props) => {
  const { t } = useTranslation('taiyoro')

  return (
    <Box>
      <TextField
        label={t('eventPickem.daily.modal.reward')}
        value={value === 0 ? "" : value}
        type='number'
        inputProps={{ inputMode: 'numeric', pattern: '[0-9-]*' }}
        onKeyDown={(event) => {
          if (['e', 'E', '+'].includes(event.key)) {
            event.preventDefault()
          }
        }}
        onChange={(event) => {
          const newValue = event.target.value === '' ? '' : Number(event.target.value)
          updateValue(newValue)
          updateStatus?.()
        }}
        fullWidth
      />
    </Box>
  )
}