import { useContext } from "react"

import { type GridValidRowModel, type GridColumns } from "@mui/x-data-grid"
import { ReorderableDataGrid } from "components/Taiyoro/LJL/2025/EventResultContents/reorderable-data-grid"
import { GeoGuessrContext, type GeoGuessrSettings } from "contexts/geoguessr"
import { useTranslation } from "react-i18next"
import { type PostListItem } from "services/Taiyoro/posts"

export type PostMinimumData = Pick<PostListItem, "id" | "title" | "titleJa">

export const NewsTable = () => {
  const { tab, loading, modifiedSettings, setModifiedSettings } = useContext(GeoGuessrContext)

  const { t } = useTranslation("taiyoro")

  if (tab !== "news") return <></>

  const columns: GridColumns<GridValidRowModel> = [
    {
      flex: 1,
      field: "titleJa",
      sortable: false,
      headerName: t("posts.listPage.tableHeaders.titleJa"),
      editable: false,
    },
    {
      flex: 1,
      field: "title",
      sortable: false,
      headerName: t("posts.listPage.tableHeaders.title"),
      editable: false,
    },
  ]

  return (
    <ReorderableDataGrid
      rows={modifiedSettings.news}
      columns={columns}
      disableColumnSelector
      disableColumnMenu
      disableVirtualization
      autoHeight={true}
      getRowHeight={() => "auto"}
      density="comfortable"
      loading={loading}
      rowCount={modifiedSettings.news.length}
      hideFooter
      onTableDataPositionalChange={(modifiedRows: GeoGuessrSettings["news"]) => {
        setModifiedSettings((previousModifiedSettings) => {
          const updatedModifiedResults = { ...previousModifiedSettings }
          updatedModifiedResults.news = [...modifiedRows]
          return { ...updatedModifiedResults }
        })
      }}
    />
  )
}
