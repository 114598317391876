import React, { useState, useEffect, useContext } from "react"

import { faPlus } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Fab, Grid, Link, List, ListItem, Stack, Tooltip } from "@mui/material"
import { TableCell, TableRow, Typography } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import SearchSelector from "components/Taiyoro/EventList/SearchSelector"
import TagFilter from "components/Taiyoro/EventList/TagFilter"
import { TaiyoroDataContext } from "contexts/tag-hierarchy"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { Link as ReactRouterDomLink } from "react-router-dom"
import { useLocalStorage } from "react-use"

import { StyleBox } from "../../../components/StyleMaterialUI"
import TableList from "../../../components/Table/TableList"
import Calendar from "../../../components/Taiyoro/EventList/Calendar"
import SummaryLink from "../../../components/Taiyoro/EventList/EventInfo/SummaryLink"
import Event from "../../../models/Taiyoro/event"
import type { EventListItem } from "../../../models/Taiyoro/event"
import { UserRole } from "../../../models/UserManagement"
import { createEvent, fetchEventList, fetchDatelessEvents } from "../../../services/Taiyoro/event"
import { fetchGames } from "../../../services/Taiyoro/games"
import { HIGH_ACCESS_ROLES } from "../../../utils/roles"
import useRolesCanAccess from "../../../utils/useRolesCanAccess"
import { RECENTLY_VIEWED_EVENTS_KEY, type RecentlyViewedEventItem } from "./summary"

export default withRouter((props) => {
  const [eventListState, setEventListState] = useState<Array<EventListItem>>([])
  const [datelessEventsState, setDatelessEventsState] = useState([])
  const [gamesState, setGamesState] = useState([])
  const [isLoadingState, setIsLoadingState] = useState(true)
  const [errorState, setErrorState] = useState("")
  const { eventListSearchParams } = useContext(TaiyoroDataContext)
  const { t, i18n } = useTranslation("taiyoro")
  const [recentlyViewedEvents] = useLocalStorage<Array<RecentlyViewedEventItem>>(
    RECENTLY_VIEWED_EVENTS_KEY,
    []
  )

  const getEvents = async () => {
    try {
      setIsLoadingState(true)
      const eventList = await fetchEventList(
        eventListSearchParams.mode === "calendar"
          ? {
              from: eventListSearchParams.month.clone().startOf("month"),
              to: eventListSearchParams.month.clone().endOf("month"),
            }
          : {
              filterTags: eventListSearchParams.filterTags,
            }
      )
      setEventListState(eventList)
      setIsLoadingState(false)
    } catch (err) {
      setErrorState(JSON.stringify(err))
      setIsLoadingState(false)
    }
  }

  const getDatelessEvents = async () => {
    try {
      const events = await fetchDatelessEvents()
      setDatelessEventsState(events)
    } catch (err) {
      setErrorState(JSON.stringify(err))
    }
  }

  const getGames = async () => {
    const games = await fetchGames()
    setGamesState(games)
  }

  useEffect(() => {
    getDatelessEvents()
    getGames()
  }, [])

  useEffect(() => {
    getEvents()
    // eslint-disable-next-line
  }, [eventListSearchParams])

  const canCreateEvent = useRolesCanAccess([...HIGH_ACCESS_ROLES, UserRole.EDITOR])

  const handleOnAdd = async () => {
    const date = new Date()
    const minimumEvent = new Event(
      null,
      "New event " + date.toLocaleDateString() + " " + date.toLocaleTimeString(),
      "new-event",
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      null,
      0,
      null,
      null,
      "JP",
      0,
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    )
    createEvent(minimumEvent).then((event) => {
      props.history.push("/taiyoro/event/" + event.save.id + "/edit")
    })
  }

  return (
    <>
      <Stack
        direction="row"
        width="100%"
        justifyContent="space-between"
        mb={1}
      >
        <SearchSelector />
        {eventListSearchParams.mode === "calendar" && <Calendar />}
        {eventListSearchParams.mode === "tags" && <TagFilter />}
        {canCreateEvent ? (
          <Tooltip title={t("edit.titles.create")}>
            <Fab
              size="medium"
              color="success"
              aria-label="add"
              onClick={handleOnAdd}
              sx={{
                flexShrink: 0,
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Fab>
          </Tooltip>
        ) : (
          <Box />
        )}
      </Stack>
      {recentlyViewedEvents && (
        <Box my={2}>
          <Typography variant="h6">{t("list.recentlyViewed")}</Typography>
          <List>
            {recentlyViewedEvents.map((recentlyViewedEvent) => (
              <ListItem key={recentlyViewedEvent.eventId}>
                <Link
                  to={`/taiyoro/event/${recentlyViewedEvent.eventId}`}
                  component={ReactRouterDomLink}
                >
                  {recentlyViewedEvent.eventName}
                </Link>
                <Typography ml={2}>
                  {moment(recentlyViewedEvent.viewed).locale(i18n.language).fromNow()}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
      <TableList size={"medium"}>
        <TableList.Header>
          <TableRow>
            <TableCell>{t("list.header.name")}</TableCell>
            <TableCell />
            <TableCell>
              <Grid
                container
                spacing={0}
              >
                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center" }}
                >
                  {t("list.header.schedule")}
                </Grid>
              </Grid>
            </TableCell>
            <TableCell>
              <Grid
                container
                spacing={0}
                style={{ minWidth: "120px" }}
              >
                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center" }}
                >
                  {t("list.header.liveStats")}
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ textAlign: "center", fontWeight: "normal" }}
                >
                  {t("list.header.pcu")}
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ textAlign: "center", fontWeight: "normal" }}
                >
                  {t("list.header.acu")}
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ textAlign: "center", fontWeight: "normal" }}
                >
                  {t("list.header.minutesWatched")}
                </Grid>
              </Grid>
            </TableCell>
            <TableList.HeaderCell>{t("list.header.dates")}</TableList.HeaderCell>
          </TableRow>
        </TableList.Header>
        <TableList.Body>
          {errorState !== "" && (
            <TableList.BodyRow colSpan={8}>
              <TableList.BodyCell>
                {t("list.table.errorMessage")}
                {errorState}
              </TableList.BodyCell>
            </TableList.BodyRow>
          )}
          {isLoadingState && (
            <TableList.BodyRow colSpan={8}>
              <TableList.BodyCell>
                <CircularProgress />
              </TableList.BodyCell>
            </TableList.BodyRow>
          )}
          {!isLoadingState && eventListState.length === 0 && errorState === "" && (
            <TableList.BodyRow colSpan={8}>
              <TableList.BodyCell>{t("list.table.noEvents")}</TableList.BodyCell>
            </TableList.BodyRow>
          )}
          {!isLoadingState &&
            eventListState &&
            eventListState.map((eventListItem) => (
              <React.Fragment key={eventListItem.id}>
                <SummaryLink
                  games={gamesState}
                  eventListItem={eventListItem}
                />
              </React.Fragment>
            ))}
        </TableList.Body>
      </TableList>

      {datelessEventsState.length > 0 && (
        <StyleBox mt={3}>
          <Typography variant="h4">{t("list.resumeTitle")}</Typography>
        </StyleBox>
      )}

      {datelessEventsState.map((event) => (
        <Box
          key={event.id}
          p={1}
        >
          <Link
            component={ReactRouterDomLink}
            to={`/taiyoro/event/${event.id}`}
          >
            {event.name}
          </Link>
        </Box>
      ))}
    </>
  )
})
