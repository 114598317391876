import React, { useState, useEffect, useContext } from "react"

import {
  FormControl,
  FormLabel,
  Grid,
  Box,
  Snackbar,
  Alert,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material"
import { EventContext } from "contexts/event"
import moment from "moment-timezone"
import { useTranslation } from "react-i18next"

import { errorContext } from "../../../.."
import type Game from "../../../../models/Taiyoro/Meta/Game"
import type Platform from "../../../../models/Taiyoro/Meta/Platform"
import type Venue from "../../../../models/Taiyoro/Meta/Venue"
import EventDateModel from "../../../../models/Taiyoro/eventDate"
import {
  deleteDatePlatform,
  deleteDate,
  saveDate,
  saveDatePlatform,
  editDate,
} from "../../../../services/Taiyoro/event"
import DropdownSearchable from "../../../Form/DropdownSearchable"
import { StyleTypography } from "../../../StyleMaterialUI"
import StyledCheckbox from "../../Form/Checkbox"
import { HelperText } from "../Information/styles"
import EventDate from "./Date"

interface Props {
  gamesState: Game[]
  availablePlatforms: Platform[]
  availableGames: Game[]
  availableVenues: Venue[]
  onDelete: (cb: () => void) => void
  onDeleteStream: (cb: () => void) => void
  onUpdate: (model: EventDateModel[]) => void
}

const Dates = ({
  gamesState,
  availableGames,
  availablePlatforms,
  availableVenues,
  onUpdate,
  onDelete,
  onDeleteStream,
}: Props) => {
  const [datesCancelledCountState, setDatesCancelledCountState] = useState(0)
  const [event, setEvent] = useContext(EventContext)
  const [showDuplicationPending, setShowDuplicationPending] = useState(false)

  const { t } = useTranslation("taiyoro")

  useEffect(() => {
    const count = event.dates.reduce((acc, cur) => acc + (cur.cancelled ? 1 : 0), 0)

    setDatesCancelledCountState(count)

    // eslint-disable-next-line
  }, [event])

  const createNewDate = async (game: Game) => {
    let model = new EventDateModel(
      null,
      "Date for " + game.name,
      moment().startOf("day").add(1, "week"),
      moment().endOf("day").add(1, "week"),
      game.id,
      null,
      0,
      null,
      [],
      false,
      0,
      0,
      null
    )
    const response = await saveDate(event.id, model)
    model.id = response.id
    setEvent((oldEvent) => {
      const newEvent = Object.assign(Object.create(Object.getPrototypeOf(oldEvent)), oldEvent)
      newEvent.dates.push(model)
      return newEvent
    })

    window.scrollTo(window.scrollX, window.scrollY)
  }

  const [errorState, setErrorState] = errorContext()
  const durationErrorMessage = t("edit.dates.errors.durationOver24hrs")
  const startAfterEndErrorMessage = t("edit.dates.errors.startTimeAfterEndTime")

  useEffect(() => {
    const startAfterEndError = event.dates.some(
      (date) => Math.floor(date.endTime.diff(date.startTime, "minutes")) <= 0
    )
    const durationError = event.dates.some(
      (date) => Math.ceil(date.endTime.diff(date.startTime, "minutes") / 60) > 24
    )
    const existingDurationError = errorState.some((error) => error === durationErrorMessage)
    const exitingStartAfterEndError = errorState.some((error) => error === startAfterEndErrorMessage)
    let newErrorState = [...errorState]
    let dirtyErrorState = false
    if (!existingDurationError && durationError) {
      newErrorState.push(durationErrorMessage)
      dirtyErrorState = true
    }
    if (!exitingStartAfterEndError && startAfterEndError) {
      newErrorState.push(startAfterEndErrorMessage)
      dirtyErrorState = true
    }
    if (existingDurationError && !durationError) {
      newErrorState = newErrorState.filter((error) => error !== durationErrorMessage)
      dirtyErrorState = true
    }
    if (exitingStartAfterEndError && !startAfterEndError) {
      newErrorState = newErrorState.filter((error) => error !== startAfterEndErrorMessage)
      dirtyErrorState = true
    }
    dirtyErrorState && setErrorState(newErrorState)
    // eslint-disable-next-line
  }, [event, errorState])

  const onCancellAllChanged = () => {
    event.dates.forEach(
      async (date) => await editDate(date.id, { isCancelled: datesCancelledCountState === 0 ? 1 : 0 })
    )
    setEvent((oldEvent) => {
      const newEvent = Object.assign(Object.create(Object.getPrototypeOf(oldEvent)), oldEvent)
      newEvent.dates.map((date) => {
        date.cancelled = datesCancelledCountState === 0 ? 1 : 0
        return date
      })
      return newEvent
    })
  }

  const handleDuplicate = async (eventDate: EventDateModel) => {
    setShowDuplicationPending(true)
    let model = new EventDateModel(
      null,
      "Copy of " + eventDate.name + " " + moment().format("YYYY/DD/MM HH:mm:ss"),
      eventDate.startTime,
      eventDate.endTime,
      eventDate.game,
      eventDate.venue,
      eventDate.offline,
      eventDate.attendees,
      eventDate.platforms,
      eventDate.estimated,
      eventDate.cancelled,
      eventDate.hidden,
      eventDate.ticket
    )
    const response = await saveDate(event.id, model)
    model.id = response.id

    Promise.allSettled(
      model.platforms.map((platform) => {
        return saveDatePlatform(model.id, platform).then((res) => {
          return res.id
        })
      })
    ).then((settled: any) => {
      for (let i = 0; i < settled.length; i++) {
        model.platforms[i].id = settled[i].value
      }
      if (event.dates.length > 0) {
        const ref = event.dates[event.dates.length - 1]
        model.startTime = ref.startTime
        model.endTime = ref.endTime
        if (ref.offline === 1) {
          model.offline = ref.offline
          model.venue = ref.venue
        }
      }

      setEvent((oldEvent) => {
        const newEvent = Object.assign(Object.create(Object.getPrototypeOf(oldEvent)), oldEvent)
        newEvent.dates.push(model)
        return newEvent
      })
      setShowDuplicationPending(false)

      window.scrollTo(window.scrollX, window.scrollY)
    })
  }

  return (
    <React.Fragment>
      <Snackbar open={showDuplicationPending}>
        <Alert severity="warning">
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
          >
            <Typography>{t("edit.dates.duplicate.wait")}</Typography>
            <CircularProgress
              size="1rem"
              color="warning"
            />
          </Stack>
        </Alert>
      </Snackbar>
      <Grid
        item
        xs={12}
      >
        <Box mb="12px">
          <Grid
            container
            spacing={3}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid
              item
              xs={9}
              container
            >
              <Grid
                item
                xs={12}
              >
                <StyleTypography variant="h5">{t("edit.dates.title")}</StyleTypography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={3}
              container
              spacing={0}
            >
              <Grid
                item
                xs={12}
              >
                <FormControl
                  component="fieldset"
                  style={{ alignItems: "start" }}
                >
                  <FormLabel
                    component="label"
                    className="MuiFormLabel-root MuiInputLabel-shrink"
                  >
                    {t("edit.dates.cancelAll")}
                  </FormLabel>
                  <StyledCheckbox
                    indeterminate={
                      event.dates.length > 0 &&
                      datesCancelledCountState > 0 &&
                      datesCancelledCountState !== event.dates.length
                    }
                    checked={event.dates.length > 0 && datesCancelledCountState === event.dates.length}
                    onChange={(e) => {
                      onCancellAllChanged()
                    }}
                    style={{ padding: "0" }}
                    monochrome
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {gamesState.length === 0 && (
          <Box sx={{ marginTop: "12px", marginBottom: "12px" }}>{t("edit.dates.errors.noGames")}</Box>
        )}
        {[...event.dates]
          .sort((a, b) => (a.startTime.isBefore(b.startTime) ? -1 : 1))
          .map((date, index) => (
            <React.Fragment key={date.id}>
              <EventDate
                eventPublishedState={event.published}
                model={date}
                onDelete={(id) => {
                  setEvent((oldEvent) => {
                    const newEvent = Object.assign(Object.create(Object.getPrototypeOf(oldEvent)), oldEvent)
                    newEvent.dates.splice(index, 1)
                    return newEvent
                  })
                  if (event.id !== null && id !== null) {
                    onDelete(() => {
                      deleteDate(id)
                    })
                  }
                }}
                onDeleteStream={(id) => {
                  if (event.id !== null && id !== null) {
                    onDeleteStream(() => {
                      deleteDatePlatform(id)
                    })
                  }
                }}
                onUpdate={(changes) => {
                  setEvent((oldEvent) => {
                    const newEvent = Object.assign(Object.create(Object.getPrototypeOf(oldEvent)), oldEvent)
                    newEvent.dates[index] = { ...newEvent.dates[index], ...changes }
                    return newEvent
                  })
                }}
                availablePlatforms={availablePlatforms}
                availableGames={availableGames}
                availableVenues={availableVenues}
                onDuplicate={handleDuplicate}
              />
            </React.Fragment>
          ))}
        <Grid
          container
          mt="12px"
        >
          <Grid
            item
            xs={9}
          >
            <DropdownSearchable
              value={[]}
              disabled={event.games.length === 0}
              options={event.games.map((eventGame) =>
                availableGames.find((game) => game.id === eventGame.gameId)
              )}
              onChange={(game) => createNewDate(game)}
              label={t("edit.dates.addNew")}
            />
          </Grid>
          <Grid
            item
            xs={9}
          >
            <HelperText>{t("edit.help.dates")}</HelperText>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default Dates
