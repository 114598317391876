import { useContext } from "react"

import { Ljl2025ResultsContext } from "contexts/ljl-2025-results"

import { ChampionshipPoints } from "./championship-points"
import { FinalsResults } from "./finals"
import { ForgeResults } from "./forge"
import { IgniteResults } from "./ignite"
import { News } from "./news"
import { StormResults } from "./storm"

export const EventResultContents = () => {
  const { tab } = useContext(Ljl2025ResultsContext)

  return (
    <>
      {tab === "championship-points" && <ChampionshipPoints />}
      {tab === "forge" && <ForgeResults />}
      {tab === "storm" && <StormResults />}
      {tab === "ignite" && <IgniteResults />}
      {tab === "finals" && <FinalsResults />}
      {tab === "news" && <News />}
    </>
  )
}
