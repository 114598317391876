import { useEffect, useState } from 'react';
import { Box, Button, ButtonGroup, CircularProgress, Dialog, DialogContent, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as XLSX from 'xlsx';
import type { GridColDef } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import { type CachedSupporters, fetchAllLJLSupportersList, fetchCachedSupportersList, updateApprovedLJLSupportersList } from 'services/Futaroku';
import { useTranslation } from 'react-i18next';

const StyledButton = styled(Button)(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.spacing(1),
}));


const LJLSupporters = () => {
    const { t } = useTranslation("taiyoro")
    const [loading, setLoading] = useState(false)
    const [initialSupporters, setInitialSupporters] = useState<Array<{ OrderId: string, name: string, status: string }>>([]);
    const [supporters, setSupporters] = useState<Array<{ OrderId: string, name: string, status: string }>>([]);
    const [isMounted, setIsMounted] = useState(true);
    const [saving, setSaving] = useState(false)

    const columns: Array<GridColDef> = [
        { field: 'order_number', headerName: 'Order ID', width: 150 },
        { field: 'name', headerName: 'Names', width: 350 },
        {
            field: 'status',
            headerName: 'Status',
            width: 350,
            renderCell: (params: { row: { order_number: string, name: string } }) => {
                const handleStatusChange = (newStatus: string) => {
                    setSupporters((prev) =>
                        prev.map((item) =>
                            item.OrderId === params.row.order_number && item.name === params.row.name
                                ? { ...item, status: newStatus }
                                : item
                        )
                    );
                };

                const foundRow = supporters.find((item) =>
                    item.OrderId === params.row.order_number && item.name === params.row.name)
                return (
                    <ButtonGroup variant="contained" aria-label="status button group">
                        <Button
                            onClick={() => handleStatusChange("")}
                            color={foundRow?.status === "" || foundRow?.status === undefined ? "primary" : "inherit"}
                        >
                            None
                        </Button>
                        <Button
                            onClick={() => handleStatusChange("Approve")}
                            color={foundRow?.status === "Approve" ? "success" : "inherit"}
                        >
                            Approved
                        </Button>
                        <Button
                            onClick={() => handleStatusChange("Reject")}
                            color={foundRow?.status === "Reject" ? "error" : "inherit"}
                        >
                            Rejected
                        </Button>
                    </ButtonGroup>
                );
            },
        }

    ];

    const downloadExcel = () => {
        const approvedSupporters = supporters
            .filter((supporter) => supporter.status === "Approve")
            .map((value) => value.name);

        const uniqueNames = Array.from(new Set(approvedSupporters));
        const dataForExcel = uniqueNames.map((name) => ({ name }));

        const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Approved Supporters');
        XLSX.writeFile(workbook, 'ApprovedSupporters.xlsx');
    };

    const downloadCSV = () => {
        const approvedSupporters = supporters
            .filter((supporter) => supporter.status === "Approve")
            .map((value) => value.name);

        const uniqueNames = Array.from(new Set(approvedSupporters));
        const dataForCSV = uniqueNames.map((name) => ({ name }));

        const worksheet = XLSX.utils.json_to_sheet(dataForCSV);
        const csv = XLSX.utils.sheet_to_csv(worksheet);

        const blob = new Blob([csv], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'ApprovedSupporters.csv';
        link.click();
    };

    const handleSendApproveList = async () => {
        setSaving(true);

        const organizedList: Record<string, Array<Record<string, string>>> = {
            "0": [],
            "1": []
        };

        supporters.forEach((item) => {
            if (item.status === "Approve") {
                organizedList["0"].push({
                    [item.OrderId]: item.name
                });
            } else if (item.status === "Reject") {
                organizedList["1"].push({
                    [item.OrderId]: item.name
                });
            }
        });

        const result = await updateApprovedLJLSupportersList(organizedList);

        setTimeout(() => {
            setSaving(result.ok && false);
            setInitialSupporters(supporters);
        }, 500);
    };

    useEffect(() => {
        setLoading(true)
        setIsMounted(true);

        const fetchSupporters = async () => {
            try {
                const supporterList = await fetchAllLJLSupportersList();
                const cachedSupporters = await fetchCachedSupportersList();
                const parsedCachedSupporters = cachedSupporters.names
                    ? (JSON.parse(cachedSupporters.names) as CachedSupporters)
                    : { list: { "0": [], "1": [] } };
                const filteredSupporters = supporterList
                    // eslint-disable-next-line @typescript-eslint/dot-notation
                    .filter((row) => row["OrderId"] !== "cachedNames")
                    .filter((row) =>
                        row.line_items.some(
                            (item) => item.properties && Object.keys(item.properties).length > 0
                        )
                    );
                const flattenedSupporters = filteredSupporters.flatMap((item) =>
                    item.line_items.flatMap((line) =>
                        line.properties
                            ?.map(
                                (property) =>
                                    property.value &&
                                    property.value !== "" &&
                                    (property.name === "サポーター名" || property.name === "Your Name") && {
                                        OrderId: item.order_number,
                                        name: property.value,
                                        status: "",
                                    }
                            )
                            .filter(Boolean)
                    )
                ) as Array<{ OrderId: string; name: string; status: string }>;

                const approvedList = parsedCachedSupporters.list["0"] ?? [];
                const rejectedList = parsedCachedSupporters.list["1"] ?? [];
                const updatedSupporters = flattenedSupporters.map((supporter) => {
                    const isApproved = approvedList.some(
                        (item: Record<string, string>) =>
                            item[supporter.OrderId] === supporter.name
                    );
                    const isRejected = rejectedList.some(
                        (item: Record<string, string>) =>
                            item[supporter.OrderId] === supporter.name
                    );

                    if (isApproved) {
                        return { ...supporter, status: "Approve" };
                    } else if (isRejected) {
                        return { ...supporter, status: "Reject" };
                    }

                    return supporter;
                });

                updatedSupporters.sort((a, b) => Number(b.OrderId) - Number(a.OrderId));

                if (isMounted) {
                    setSupporters(updatedSupporters)
                    setInitialSupporters(updatedSupporters)
                }
            } catch (error) {
                console.error("Error fetching supporters:", error);
            }
        };


        void fetchSupporters();
        setLoading(false)
        return () => setIsMounted(false);
    }, []);

    return (
        <>
            <Box>
                <Box sx={{ display: "flex" }}>
                    <StyledButton onClick={downloadExcel} sx={{ marginLeft: "auto" }}>
                        Download As Excel
                    </StyledButton>

                    <StyledButton onClick={downloadCSV}>
                        Download As CSV
                    </StyledButton>
                </Box>

                <Button
                    variant="contained"
                    type="button"
                    onClick={() => { void handleSendApproveList() }}
                    disabled={initialSupporters === supporters}
                    color="primary"
                    sx={(theme) => ({
                        marginLeft: "auto",
                        display: "flex",
                        marginBottom: theme.spacing(2)
                    })}
                >
                    {t("posts.detailsPage.save")}
                </Button>

                <Box sx={{ height: 'auto', width: '100%' }}>
                    <DataGrid
                        autoHeight
                        rows={supporters.map((row, index) => ({
                            id: index,
                            order_number: row.OrderId,
                            name: row.name,
                        }))}
                        columns={columns}
                        hideFooter
                    />
                </Box>
            </Box>

            <Dialog open={saving}>
                <DialogContent>
                    <CircularProgress />
                </DialogContent>
            </Dialog>

            {loading && (
                <Stack
                    sx={{ height: "50%" }}
                    justifyContent="center"
                    alignItems="center"
                >
                    <CircularProgress />
                </Stack>
            )}
        </>
    );
};

export default LJLSupporters;
