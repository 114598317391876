import React from "react"

import { useTranslation } from "react-i18next"

import MetasPage from "../../../components/Taiyoro/MetaPage"
import { MetaType } from "../../../models/Taiyoro/Meta/MetaType"
import {
  fetchGameGroups,
  createGameGroup,
  link,
  deleteLink,
  deleteGameGroup,
} from "../../../services/Taiyoro/gameGroups"

const MetaGameGroupPage = () => {
  const { t } = useTranslation("taiyoro")

  return (
    <MetasPage
      title={t("meta.pageTitles.gameGroups")}
      fetchFunc={fetchGameGroups}
      addNewFunc={(
        name,
        nameJa,
        sortJa,
        isGameDeveloper,
        primaryImage,
        secondaryImage,
        boxArtImage,
        latitude,
        longitude,
        team,
        teamOrganizationId,
        hashtag,
        gameId,
        urlSlug,
        quantifiable,
        homepageUrl,
        isGenre,
        id
      ) => createGameGroup(name, nameJa, sortJa, urlSlug, primaryImage, secondaryImage, isGenre, id)}
      updateFunc={(
        name,
        nameJa,
        sortJa,
        isGameDeveloper,
        primaryImage,
        secondaryImage,
        boxArtImage,
        latitude,
        longitude,
        team,
        teamOrganizationId,
        hashtag,
        gameId,
        urlSlug,
        quantifiable,
        homepageUrl,
        isGenre,
        id
      ) => createGameGroup(name, nameJa, sortJa, urlSlug, primaryImage, secondaryImage, isGenre, id)}
      deleteFunc={deleteGameGroup}
      primaryImageFieldsAndRatio={16 / 9}
      secondaryImageFieldsAndRatio={1}
      updateGroupsFunc={(added, removed) =>
        Promise.all([
          ...added.map((groupData) => link(MetaType.GAME_GROUP, groupData.refId, groupData.parentGroupId)),
          ...removed.map((groupData) =>
            deleteLink(MetaType.GAME_GROUP, groupData.refId, groupData.parentGroupId)
          ),
        ])
      }
      metaType={MetaType.GAME_GROUP}
      isGenreFields
      slugFields
    />
  )
}

export default MetaGameGroupPage
