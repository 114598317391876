import React, { useEffect } from "react"
import type { SetStateAction } from "react"

import { Stack, Select, MenuItem, Pagination } from "@mui/material"
import type { SelectChangeEvent } from "@mui/material"

import { type Paginate, paginate } from "./helpers"

type FrontEndPaginationProps<T> = {
  listData: Array<T>
  paginateNumberOfRecords: number
  setPaginateNumberOfRecords: React.Dispatch<SetStateAction<number>>
  paginatePage: number
  setPaginatePage: React.Dispatch<SetStateAction<number>>
  setPaginatedListData: React.Dispatch<Paginate<T>>
}

export const FrontEndPagination = <T,>({
  listData,
  paginateNumberOfRecords,
  setPaginateNumberOfRecords,
  paginatePage,
  setPaginatePage,
  setPaginatedListData,
}: FrontEndPaginationProps<T>) => {
  const pageCount = Math.ceil(listData.length / paginateNumberOfRecords)

  const handleChangepaginateNumberOfRecords = (event: SelectChangeEvent<number>) => {
    setPaginateNumberOfRecords(event.target.value as number)
    setPaginatePage(1)
  }

  const handleChangePaginate = (_, value: SetStateAction<number>) => setPaginatePage(value)

  useEffect(() => {
    const result = paginate(listData, paginateNumberOfRecords, paginatePage)

    if (!result) {
      setPaginatedListData([])
      return
    }

    setPaginatedListData(result)
  }, [listData, paginatePage, paginateNumberOfRecords, setPaginatedListData])

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        p: 2,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Select
        labelId="meta-pagination"
        id="meta-pagination"
        value={paginateNumberOfRecords}
        onChange={handleChangepaginateNumberOfRecords}
        sx={{
          fontSize: "0.875rem",
          "& .MuiSelect-select": {
            padding: "6px 16px",
          },
          "& .MuiMenuItem-root": {
            fontSize: "0.875rem",
          },
        }}
      >
        {[5, 10, 20, 50, 100, 200, 300, 400, 500].map((value) => (
          <MenuItem
            key={value}
            value={value}
          >
            {value}
          </MenuItem>
        ))}
      </Select>

      <Pagination
        count={pageCount}
        page={paginatePage}
        onChange={handleChangePaginate}
        variant="outlined"
        shape="rounded"
      />
    </Stack>
  )
}
