import { useEffect } from "react"
import { Box, TextField } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import moment from "moment"
import { useTranslation } from "react-i18next"
import DateTextField from "components/Form/DateTextField"

interface Props {
  value: string
  updateValue: (value: string) => void
  updateStatus?: () => void
}

export const DeadlineControl = ({ value, updateValue, updateStatus }: Props) => {
  const { t } = useTranslation("taiyoro")

  useEffect(() => {
    if (value === "") {
      const date = moment(new Date()).utc()
      date.seconds(0)
      updateValue(date.toISOString())
    }
  }, [value])

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateTextField
          label={t("eventPickem.daily.modal.deadline")}
          value={moment.utc(value).tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm")}
          onChange={(e: { target: { value: string } }) => {
            const newDate = moment(e.target.value, "YYYY-MM-DD HH:mm").toISOString()
            updateValue(newDate)
            updateStatus?.()
          }}
          renderInput={(props) => (
            <TextField
              {...props}
              fullWidth
            />
          )}
        />
      </LocalizationProvider>
    </Box>
  )
}
