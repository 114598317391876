import { useState } from "react"

import { Dialog, DialogContent, DialogActions, Button } from "@mui/material"
import { EventSearchAutocomplete } from "components/EventSearchAutocomplete"
import { t } from "i18next"
import type Event from "models/Taiyoro/event"

interface Props {
  open: boolean
  onEventSelected: (post: Event) => void
  onClose: () => void
}

export const EventSelectionDialog = (props: Props) => {
  const [event, setEvent] = useState<Event | null>(null)

  const handleSave = () => {
    if (!event) {
      props.onClose()
      return
    }
    props.onEventSelected(event)
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth
    >
      <DialogContent dividers>
        <EventSearchAutocomplete onEventSelected={setEvent} />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>{t("common:actions.cancel")}</Button>
        <Button onClick={handleSave}>{t("common:actions.save")}</Button>
      </DialogActions>
    </Dialog>
  )
}
