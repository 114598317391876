import { type MouseEvent, useContext, useState } from "react"

import { faCopy } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Tooltip, styled } from "@mui/material"
import { GridActionsCellItem } from "@mui/x-data-grid"
import { PickemFunctionsContext } from "contexts/pickem"
import { useTranslation } from "react-i18next"
import { type DailyPickemAny } from "services/Taiyoro/Pickem/daily"

const StyledIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  fontSize: theme.spacing(2.4),
}))

export const DuplicateRecord = ({ params }: { params: { row: DailyPickemAny } }) => {
  const { t } = useTranslation("taiyoro")
  const { createNewDailyPickem } = useContext(PickemFunctionsContext)

  const duplicateRecordPickem = (row: DailyPickemAny) => {
    const sanitizedRow = {
      id: "",
      status: "new",
      eventId: row.eventId,
      dateId: row.dateId,
      prompt: row.prompt,
      promptJa: row.promptJa,
      reward: row.reward,
      deadline: row.deadline,
      correctAnswer: null,
      pointsHaveBeenAwarded: 0,
      awardedPointValue: null,
      type: row.type,
      groupId: row.groupId,
      priority: null,
      isPublic: 0,
      isDraft: 0,
      answerOptions: row.answerOptions,
      groupAndPriority: 0,
    } as DailyPickemAny

    createNewDailyPickem(sanitizedRow)

    handleClose()
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClose = () => setAnchorEl(null)

  return (
    <GridActionsCellItem
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...({} as any)}
      icon={
        <Tooltip
          title={t("eventPickem.daily.actions.duplicate")}
          enterDelay={0}
        >
          <StyledIcon icon={faCopy} />
        </Tooltip>
      }
      aria-controls={open ? "positioned-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={open ? "true" : undefined}
      onClick={() => duplicateRecordPickem(params.row)}
      label={t("eventPickem.daily.actions.duplicate")}
    />
  )
}
