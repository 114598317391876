import { type Dispatch, type SetStateAction, createContext } from "react"

import type Team from "models/Taiyoro/Meta/Team"
import { type PostListItem } from "services/Taiyoro/posts"

export type LJL2025Tab = "championship-points" | "forge" | "storm" | "ignite" | "finals" | "news"

export type LJLMatchResult = {
  opposingTeamId: string
  victory: boolean
  points: string | null
}

export type LJL2025Results = {
  "championship-points": Array<{
    id: string
    // Intentionally a string type for the highly likely edge-case inputs like "DISQ., =2 (ties) etc"
    rank: string
    teamId: string
    forge: string
    storm: string
    ignite: string
    total: string
  }>
  forge: {
    eventId: string | null
    openStageImage: string | null
    mainStageImage: string | null
    knockoutStageImage: string | null
    tableData: Array<{
      id: string
      rank: string
      teamId: string
      1: LJLMatchResult | null
      2: LJLMatchResult | null
      3: LJLMatchResult | null
      4: LJLMatchResult | null
      5: LJLMatchResult | null
      record: string
      knockoutRank: string
      championshipPoints: string
    }>
  }
  storm: {
    eventId: string | null
    openStageImage: string | null
    mainStageImage: string | null
    knockoutStageImage: string | null
    tableData: Array<{
      id: string
      rank: string
      teamId: string
      1: LJLMatchResult | null
      2: LJLMatchResult | null
      3: LJLMatchResult | null
      4: LJLMatchResult | null
      5: LJLMatchResult | null
      6: LJLMatchResult | null
      7: LJLMatchResult | null
      8: LJLMatchResult | null
      9: LJLMatchResult | null
      stormPoints: string
      knockoutRank: string
      championshipPoints: string
    }>
  }
  ignite: {
    eventId: string | null
    openStageImage: string | null
    mainStageImage: string | null
    knockoutStageImage: string | null
    tableData: Array<{
      id: string
      rank: string
      teamId: string
      1: LJLMatchResult | null
      2: LJLMatchResult | null
      3: LJLMatchResult | null
      4: LJLMatchResult | null
      5: LJLMatchResult | null
      6: LJLMatchResult | null
      ignitePoints: string
      knockoutRank: string
      knockoutPoints: string
      championshipPoints: string
    }>
  }
  finals: {
    image: string | null
  }
  news: Array<Pick<PostListItem, "id" | "title" | "titleJa">>
}

export interface TeamWithLabel extends Team {
  value: string
  label: string
}

export const RESULTS_DEFAULT_VALUES: LJL2025Results = {
  "championship-points": [],
  forge: {
    eventId: null,
    openStageImage: null,
    mainStageImage: null,
    knockoutStageImage: null,
    tableData: [],
  },
  storm: {
    eventId: null,
    openStageImage: null,
    mainStageImage: null,
    knockoutStageImage: null,
    tableData: [],
  },
  ignite: {
    eventId: null,
    openStageImage: null,
    mainStageImage: null,
    knockoutStageImage: null,
    tableData: [],
  },
  finals: {
    image: null,
  },
  news: [],
}

type Ljl2025ContextType = {
  loading: boolean
  tab: LJL2025Tab
  setTab: Dispatch<SetStateAction<LJL2025Tab>>
  savedResults: LJL2025Results
  save: () => void
  viewDraft: () => void
  modifiedResults: LJL2025Results
  setModifiedResults: Dispatch<SetStateAction<LJL2025Results>>
  teams: Array<TeamWithLabel>
  forgeTeams: Array<TeamWithLabel>
  stormTeams: Array<TeamWithLabel>
  igniteTeams: Array<TeamWithLabel>
}

export const Ljl2025ResultsContext = createContext<Ljl2025ContextType>({
  loading: false,
  tab: "championship-points",
  setTab: () => undefined,
  savedResults: RESULTS_DEFAULT_VALUES,
  save: () => undefined,
  viewDraft: () => undefined,
  modifiedResults: RESULTS_DEFAULT_VALUES,
  setModifiedResults: () => undefined,
  teams: [],
  forgeTeams: [],
  stormTeams: [],
  igniteTeams: [],
})
