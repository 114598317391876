import { useCallback, useContext, useState } from "react"

import { faSpinner } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Autocomplete, TextField } from "@mui/material"
import { NotificationContext } from "contexts/notification"
import { useTranslation } from "react-i18next"
import { useEffectOnce } from "react-use"
import { fetchPagedPosts, type PostListItem } from "services/Taiyoro/posts"

interface Props {
  postId: string | null
  onPostChanged: (post: PostListItem | null) => void
}

export const PostsAutocomplete = (props: Props) => {
  const [options, setOptions] = useState<Array<PostListItem>>([])
  const [loading, setLoading] = useState(false)
  const { setNotification } = useContext(NotificationContext)
  const { t, i18n } = useTranslation("common")

  const handleError = useCallback(
    (error: unknown) => {
      setNotification({
        message: typeof error === "string" ? error : "Error occured",
        severity: "error",
      })
    },
    [setNotification]
  )

  useEffectOnce(() => {
    setLoading(true)
    fetchPagedPosts({ page: 0, size: 200 })
      .then((response) => {
        if (response.status !== "success") {
          throw Error()
        }
        setOptions(response.data.results)
        setLoading(false)
      })
      .catch(handleError)
      .finally(() => {
        setLoading(false)
      })
  })

  const getLocalizedTitle = (item: PostListItem) => {
    if (i18n.language === "en") {
      return item.title ?? item.titleJa
    }

    return item.titleJa
  }

  return (
    <>
      {options.length > 0 && (
        <Autocomplete
          loading={loading}
          loadingText={
            <FontAwesomeIcon
              icon={faSpinner}
              spin
            />
          }
          fullWidth
          filterOptions={(x) => x}
          options={options}
          autoComplete
          getOptionLabel={getLocalizedTitle}
          includeInputInList
          filterSelectedOptions
          value={options.find((post) => post.id === props.postId) ?? null}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(_event, newValue) => {
            props.onPostChanged(newValue)
          }}
          noOptionsText={t("postSearch.noOptions")}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("postSearch.placeholder")}
            />
          )}
        />
      )}
    </>
  )
}
