import { useContext } from "react"

import { type GridValidRowModel, type GridColumns } from "@mui/x-data-grid"
import { type LJL2025Results, Ljl2025ResultsContext } from "contexts/ljl-2025-results"
import { useTranslation } from "react-i18next"
import { type PostListItem } from "services/Taiyoro/posts"

import { ReorderableDataGrid } from "./reorderable-data-grid"

export type PostMinimumData = Pick<PostListItem, "id" | "title" | "titleJa">

export const News = () => {
  const { loading, modifiedResults, setModifiedResults } = useContext(Ljl2025ResultsContext)

  const { t } = useTranslation("taiyoro")

  const columns: GridColumns<GridValidRowModel> = [
    {
      flex: 1,
      field: "titleJa",
      sortable: false,
      headerName: t("posts.listPage.tableHeaders.titleJa"),
      editable: false,
    },
    {
      flex: 1,
      field: "title",
      sortable: false,
      headerName: t("posts.listPage.tableHeaders.title"),
      editable: false,
    },
  ]

  return (
    <ReorderableDataGrid
      rows={modifiedResults.news}
      columns={columns}
      disableColumnSelector
      disableColumnMenu
      disableVirtualization
      autoHeight={true}
      getRowHeight={() => "auto"}
      density="comfortable"
      loading={loading}
      rowCount={modifiedResults.news.length}
      hideFooter
      onTableDataPositionalChange={(modifiedRows: LJL2025Results["news"]) => {
        setModifiedResults((previousModifiedResults) => {
          const updatedModifiedResults = { ...previousModifiedResults }
          updatedModifiedResults.news = [...modifiedRows]
          return { ...updatedModifiedResults }
        })
      }}
    />
  )
}
