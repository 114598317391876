import React, { useState, useMemo } from "react"

import { styled } from "@mui/material"
import { useTranslation } from "react-i18next"
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, LabelList, Label, Tooltip } from "recharts"

const ChartWrapper = styled(ResponsiveContainer)(({ theme }) => ({
  position: "relative",
  "& .yAxis": {
    display: "none",
  },
  "& .recharts-wrapper svg": {
    overflow: "inherit !important",
  },
}))

const renderCustomizedLabel = (props) => {
  const { content, x, y, value, index, width, height, totalUsers, ...rest } = props

  const {
    t,
    i18n: { language },
  } = useTranslation("taiyoro")

  let name = content?.nameJa || content.name
  if (language === "en") name = content.name || content?.nameJa
  const labelName = name || content?.selection
  const usersSelected = content?.totalUsers || 0
  const percentage = (usersSelected / totalUsers) * 100

  return (
    <>
      {percentage && percentage > 10 && (
        <>
          <Label
            {...rest}
            fontSize="12"
            fill="#FFFFFF"
            fontWeight="Bold"
            x={x}
            y={100}
            textAnchor="middle"
            style={{ transform: "translate(0, -20px)" }}
          >
            {labelName}
          </Label>

          {percentage && (
            <>
              <Label
                {...rest}
                fontSize="12"
                fill="#FFFFFF"
                fontWeight="Bold"
                x={x}
                y={125}
                textAnchor="middle"
              >
                {`${usersSelected} users`}
              </Label>

              <Label
                {...rest}
                fontSize="12"
                fill="#FFFFFF"
                fontWeight="Bold"
                x={x}
                y={150}
                textAnchor="middle"
                style={{ transform: "translate(0, 20px)" }}
              >
                {`${percentage.toFixed(2)}%`}
              </Label>
            </>
          )}
        </>
      )}
    </>
  )
}

export const StackedChart = ({ answers, correctAnswerId, answersList }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("taiyoro")

  const [toolTipDetails, setToolTipDetails] = useState<{
    hoveredId: number | null
    hoveredValue: number | null
  }>({
    hoveredId: null,
    hoveredValue: null,
  })

  const { sortedAnswers, totalUsers } = useMemo(() => {
    const sorted = [...answers]
      .map((answer) => {
        const foundItems = answersList.find((item) => item.id === answer.selection)
        return { ...answer, ...foundItems }
      })
      .sort((a, b) => {
        if (a.selection === correctAnswerId) return -1
        if (b.selection === correctAnswerId) return 1
        return 0
      })

    const total = sorted.reduce((sum, answer) => sum + answer.totalUsers, 0)

    return { sortedAnswers: sorted, totalUsers: total }
  }, [answers, answersList, correctAnswerId])

  const data = useMemo(
    () => [
      {
        ...sortedAnswers.reduce((acc, answer) => {
          const percentage = (answer.totalUsers / totalUsers) * 100

          acc[answer.selection] = Math.floor(percentage * 100) / 100

          return acc
        }, {}),
      },
    ],
    [sortedAnswers, totalUsers]
  )

  const showTooltip = (e) => {
    const hoveredId = e.tooltipPayload[0].name
    const hoveredValue = e[hoveredId]

    setToolTipDetails({
      hoveredId,
      hoveredValue,
    })
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const hoveredId = toolTipDetails.hoveredId
      const hoveredValue = toolTipDetails.hoveredValue
      const answer = sortedAnswers.find((answer) => answer.id === hoveredId)

      if (answer) {
        const name = language === "ja" && answer.nameJa && answer.nameJa !== "" ? answer.nameJa : answer.name

        return (
          <div
            className="custom-tooltip"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              padding: "10px",
              borderRadius: "5px",
              zIndex: 99,
            }}
          >
            {name}: {hoveredValue ? hoveredValue.toFixed(2) : "0.00"}%
          </div>
        )
      }
    }

    return null
  }

  return (
    <ChartWrapper
      width="100%"
      height={150}
    >
      <BarChart
        layout="vertical"
        data={data}
        margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
      >
        <XAxis
          type="number"
          tickCount={25}
          domain={[0, 100]}
          fontSize="9"
        />
        <YAxis
          type="category"
          dataKey="name"
          stroke="#FFFFFF"
          fontSize="12"
          height={0}
          width={0}
        />
        <Tooltip
          content={
            <CustomTooltip
              active={undefined}
              payload={undefined}
              label={undefined}
            />
          }
        />

        {sortedAnswers.map((answer, index) => {
          return (
            <Bar
              key={index}
              dataKey={answer.selection}
              stackId="a"
              fill={answer.selection === correctAnswerId ? "#82ba7f" : "#dd7876"}
              onMouseOver={showTooltip}
            >
              <LabelList
                dataKey={answer.selection}
                position="center"
                content={(props) =>
                  renderCustomizedLabel({ ...props, content: answer, totalUsers, dataId: index })
                }
              />
            </Bar>
          )
        })}
      </BarChart>
    </ChartWrapper>
  )
}
