import { type ReactNode, useState } from "react"

import { Alert } from "@mui/material"
import {
  type LJL2025Results,
  Ljl2025ResultsContext,
  RESULTS_DEFAULT_VALUES,
  type LJL2025Tab,
  type TeamWithLabel,
} from "contexts/ljl-2025-results"
import type Event from "models/Taiyoro/event"
import { useTranslation } from "react-i18next"
import { useEffectOnce, useUpdateEffect } from "react-use"
import { fetchEvent } from "services/Taiyoro/event"
import { DRAFT_MODE_SECRET, fetchJsonDump, saveJsonDump } from "services/Taiyoro/jsonDump"
import { fetchTeams } from "services/Taiyoro/teams"
import { merge } from "ts-deepmerge"
import { openLinkInNewTab } from "utils/tools"

export const LJL_RESULTS_KEY = "ljl-2025-results"

const getTeamName = (language: string, team: { name?: string; nameJa?: string; urlSlug: string }): string => {
  const { name, nameJa, urlSlug } = team

  if (language === "en") {
    return (name ?? nameJa ?? "") + ` (${urlSlug})`
  }

  if (language === "ja") {
    return (nameJa ?? name ?? "") + ` (${urlSlug})`
  }

  return ""
}

const filterTeamsForEvent = (event: Event | null, teams: Array<TeamWithLabel>) => {
  if (!event) return [] as Array<TeamWithLabel>
  return teams.filter((team) =>
    event.games.some((game) =>
      game.participants.teams.some((participatingTeam) => participatingTeam.team?.id === team.id)
    )
  )
}

export const DataProvider = ({ children }: { children: ReactNode }) => {
  const { t, i18n } = useTranslation()

  const [tab, setTab] = useState<LJL2025Tab>("championship-points")
  const [savedResults, setSavedResults] = useState<LJL2025Results>(RESULTS_DEFAULT_VALUES)
  const [modifiedResults, setModifiedResults] = useState<LJL2025Results>(RESULTS_DEFAULT_VALUES)
  const [teams, setTeams] = useState<Array<TeamWithLabel>>([])
  const [forgeTeams, setForgeTeams] = useState<Array<TeamWithLabel>>([])
  const [stormTeams, setStormTeams] = useState<Array<TeamWithLabel>>([])
  const [igniteTeams, setIgniteTeams] = useState<Array<TeamWithLabel>>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const fetchStoredResultsData = () =>
    fetchJsonDump(LJL_RESULTS_KEY).then((res) => {
      // NULL is an expected result. It means the data has never been set before
      if (res === null) return
      const parsedResults = JSON.parse(res) as LJL2025Results
      const mergedResults = merge(RESULTS_DEFAULT_VALUES, parsedResults)
      setSavedResults(mergedResults)
      // Intentionally using object reference to understand if there's no changes by object comparison
      // All calls to setModifiedResults should destructure the object and break referencial equality
      setModifiedResults(mergedResults)
    })

  const fetchAllTeams = () =>
    fetchTeams()
      .then((teamsResponse) => {
        if (!teamsResponse) return []
        const orderedTeams = teamsResponse
          .map((team) => ({ ...team, label: getTeamName(i18n.language, team), value: team.id }))
          .sort((a, b) => a.label.localeCompare(b.label))
        setTeams(orderedTeams)
        return orderedTeams
      })
      .then(calculateParticipatingTeams)

  const load = () => {
    setLoading(true)
    Promise.all([fetchStoredResultsData(), fetchAllTeams()])
      .catch(() => setError(true))
      .finally(() => setLoading(false))
  }

  const save = () => {
    setLoading(true)
    const payload = {
      id: LJL_RESULTS_KEY,
      type: "LJL",
      data: JSON.stringify(modifiedResults),
    }
    saveJsonDump(payload, "SYSTEM")
      .then(() => {
        setSavedResults(modifiedResults)
      })
      .catch(() => setError(error))
      .finally(() => setLoading(false))
  }

  const viewDraft = () => {
    setLoading(true)
    saveJsonDump({ type: "LJL", data: JSON.stringify(modifiedResults) })
      .then((draftId) => {
        const url = new URL(
          (process.env.REACT_APP_TAIYORO_DOMAIN
            ? `${process.env.REACT_APP_TAIYORO_DOMAIN}/new`
            : "http://localhost:3000/new") + "/api/draft"
        )
        const slug = `/${i18n.language}/ljl2025/results`
        url.searchParams.append("slug", slug)
        url.searchParams.append("secret", DRAFT_MODE_SECRET)
        url.searchParams.append("draftId", draftId)
        openLinkInNewTab(url.toString())
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false))
  }

  useEffectOnce(() => {
    load()
  })

  const calculateParticipatingTeams = (fetchedTeams: Array<TeamWithLabel>) => {
    const fetchEventForId = (id: string | null): Promise<Event | null> => {
      if (!id) return new Promise((resolve) => resolve(null))
      return fetchEvent(id)
    }
    return Promise.all([
      fetchEventForId(modifiedResults.forge.eventId),
      fetchEventForId(modifiedResults.storm.eventId),
      fetchEventForId(modifiedResults.ignite.eventId),
    ]).then(([forge, storm, ignite]) => {
      const filteredForge = filterTeamsForEvent(forge, fetchedTeams)
      const filteredStorm = filterTeamsForEvent(storm, fetchedTeams)
      const filteredIgnite = filterTeamsForEvent(ignite, fetchedTeams)
      setForgeTeams(filteredForge)
      setStormTeams(filteredStorm)
      setIgniteTeams(filteredIgnite)
    })
  }

  useUpdateEffect(() => {
    void calculateParticipatingTeams(teams)
  }, [teams, modifiedResults.forge.eventId, modifiedResults.storm.eventId, modifiedResults.ignite.eventId])

  return (
    <Ljl2025ResultsContext.Provider
      value={{
        tab,
        setTab,
        savedResults,
        save,
        viewDraft,
        modifiedResults,
        setModifiedResults,
        loading,
        teams,
        forgeTeams,
        stormTeams,
        igniteTeams,
      }}
    >
      {error && <Alert severity="error">{t("common:errorLoadingData")}</Alert>}
      {children}
    </Ljl2025ResultsContext.Provider>
  )
}
