import GraphQL from "utils/graphql"

import type { APIResponse } from "./types/common"

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS ?? "http://localhost:3999/api/graph"

const runQuery = async (query: string) =>
  await GraphQL(
    query,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

export type DailyPickemStatsAnswers = {
  selection: string
  totalUsers: number
}

export type CorrectAnswer = {
  nameJa: string
  name: string
  id: string
}

export type DailyPickemStats = {
  id: string
  date: DateBase
  type: string
  prompt: string
  promptJa: string
  reward: number
  dateTaiyoroRating: number
  deadline: string
  correctAnswer: CorrectAnswer | string | null
  answerOptions: DailyPickemAnswerOptions
  answers: Array<DailyPickemStatsAnswers>
  priority: number
  groupId: String
  groupPriority: number
  isPublic: number
}

export type DailyPickemAnswerOptions = {
  type: string
  data: string | Array<{ id: string; type: string }>
}

export type DateBase = {
  id: string
  name: string
  gameId: string
  startDatetime: string
  endDatetime: string
  attendees: number
  venueId: string
  isOffline: number
  isCancelled: number
  isEstimated: number
  isHidden: number
}

type FetchDailyPickemStatsResponse = APIResponse<Array<DailyPickemStats>>

export const fetchDailyPickemStats = async (eventId: string): Promise<FetchDailyPickemStatsResponse> => {
  try {
    const query = `query {
      pickem {
        getDailyPickemStatisticsForEvent(eventId: "${eventId}"){
          id
          date {
            id
            name
            gameId
            startDatetime
            endDatetime
            attendees
            venueId
            isOffline
            isCancelled
            isEstimated
            isHidden
          }
          type
          prompt
          promptJa
          reward
          dateTaiyoroRating
          deadline
          correctAnswer
          answerOptions {
            type
            data
          }
          answers {
            selection
            totalUsers
          }
          priority
          groupId
          groupPriority
          isPublic
        }
      }
    }`

    const response = await runQuery(query)

    return (
      (response?.data?.pickem?.getDailyPickemStatisticsForEvent && {
        status: "success",
        data: response.data.pickem.getDailyPickemStatisticsForEvent,
      }) || {
        status: "error",
      }
    )
  } catch (error) {
    return {
      status: "error",
    }
  }
}
