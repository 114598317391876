import React, { useContext, useEffect, useState } from "react"

import { faMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { styled } from "@mui/styles"
import { Search, SearchIconWrapper, StyledInputBase } from "components/PageLayout/QuickSearch/styles"
import { MetaPageContext } from "contexts/meta-page"
import { useDebounce } from "react-use"

const StyledSearch = styled(Search)(() => ({
  marginLeft: "0px",
  margin: "1rem 0",
}))

const StyledSearchInputBase = styled(StyledInputBase)(() => ({
  width: "100%",
  "& input": {
    width: "100%",
  },
}))

type Props = {
  placeholderText: string
}

export const TableSearch = ({ placeholderText }: Props) => {
  const { setMetaListState, preserveMetaListState, setPaginatePage } = useContext(MetaPageContext)
  const [searchTermState, setSearchTermState] = useState("")

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    if (!value) {
      setMetaListState(preserveMetaListState)
    }

    setSearchTermState(value)
  }

  const handleSearch = (searchTerm: string) => {
    setMetaListState(preserveMetaListState)
    if (!searchTerm) return

    const filteredResults = preserveMetaListState.filter((item) =>
      Object.values(item).some((value) => {
        if (value) return value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      })
    )

    setMetaListState(filteredResults)
  }

  useDebounce(
    () => {
      if (searchTermState !== "") {
        setPaginatePage(1)
        handleSearch(searchTermState)
      }
    },
    200,
    [searchTermState]
  )

  return (
    <StyledSearch>
      <SearchIconWrapper>
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </SearchIconWrapper>
      <StyledSearchInputBase
        placeholder={placeholderText}
        value={searchTermState}
        onChange={handleInputChange}
      />
    </StyledSearch>
  )
}
