import { faArrowDown, faFilter } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Stack,
  Link,
  IconButton,
  Tooltip,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { ADMIN_ROLES } from "utils/roles"
import useRolesCanAccess from "utils/useRolesCanAccess"

import { RankedDataSort } from ".."
import type { RankedStreamerResult } from "../../../../models/Scouting"
import type Game from "../../../../models/Taiyoro/Meta/Game"
import { localisedLabel } from "../../../../utils/i18n"
import { SortArrow } from "../../../Taiyoro/Analytics/Table/styles"

interface Props {
  games: Array<Game>
  results: Array<RankedStreamerResult>
  sort: RankedDataSort
  onSortChange: (sort: RankedDataSort) => void
  onAddToIgnoreList: (sort: RankedStreamerResult) => void
}

const RankedStreamerResults = (props: Props) => {
  const { t } = useTranslation(["scouting", "taiyoro", "data-collection"])

  const isAdmin = useRolesCanAccess(ADMIN_ROLES)

  return (
    <Stack spacing={1}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography noWrap>{t("data-collection:explore.table.userName")}</Typography>
            </TableCell>
            <TableCell>
              <Typography noWrap>{t("taiyoro:analytics.results.GAME_NAME")}</Typography>
            </TableCell>
            <TableCell>
              <Typography noWrap>{t("taiyoro:analytics.results.PLATFORM_GAME_NAME")}</Typography>
            </TableCell>
            <TableCell>
              <Typography noWrap>{t("taiyoro:analytics.results.PLATFORM_NAME")}</Typography>
            </TableCell>
            <TableCell>
              <Typography
                noWrap
                sx={{ cursor: "pointer" }}
                onClick={() => props.onSortChange(RankedDataSort.PCU_DESC)}
              >
                {t("taiyoro:analytics.results.PEAK_CONCURRENTS")}
                {props.sort === RankedDataSort.PCU_DESC && (
                  <SortArrow>
                    <FontAwesomeIcon
                      color="#f30362"
                      icon={faArrowDown}
                    />
                  </SortArrow>
                )}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                noWrap
                sx={{ cursor: "pointer" }}
                onClick={() => props.onSortChange(RankedDataSort.ACU_DESC)}
              >
                {t("taiyoro:analytics.results.AVERAGE_CONCURRENTS")}
                {props.sort === RankedDataSort.ACU_DESC && (
                  <SortArrow>
                    <FontAwesomeIcon
                      color="#f30362"
                      icon={faArrowDown}
                    />
                  </SortArrow>
                )}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                noWrap
                sx={{ cursor: "pointer" }}
                onClick={() => props.onSortChange(RankedDataSort.MINUTES_WATCHED_DESC)}
              >
                {t("taiyoro:analytics.results.MINUTES_WATCHED")}
                {props.sort === RankedDataSort.MINUTES_WATCHED_DESC && (
                  <SortArrow>
                    <FontAwesomeIcon
                      color="#f30362"
                      icon={faArrowDown}
                    />
                  </SortArrow>
                )}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography noWrap>{t("taiyoro:analytics.results.LIVE_BROADCAST_DURATION_MINUTES")}</Typography>
            </TableCell>
            {/* Empty cell for actions column   */}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {props.results.map((result) => (
            <TableRow key={result.userName + result.gameName}>
              <TableCell>
                <Link
                  href={result.channelUrl}
                  target="_blank"
                >
                  {result.userName}
                </Link>
              </TableCell>
              <TableCell>
                {result.taiyoroGameId
                  ? localisedLabel(props.games.find((game) => game.id === result.taiyoroGameId))
                  : result.gameName}
              </TableCell>
              <TableCell>{result.platformGameName}</TableCell>
              <TableCell>{result.platformName}</TableCell>
              <TableCell>{Number(result.viewsLiveConcurrentPeak).toLocaleString()}</TableCell>
              <TableCell>{Number(result.viewsLiveConcurrentAverage).toLocaleString()}</TableCell>
              <TableCell>{Number(result.viewsLiveMinutesWatched).toLocaleString()}</TableCell>
              <TableCell>{Number(result.liveBroadcastDuration).toLocaleString()}</TableCell>
              <TableCell>
                {isAdmin && (
                  <Tooltip title={t("ignore")}>
                    <IconButton
                      onClick={() => props.onAddToIgnoreList(result)}
                      color="error"
                    >
                      <FontAwesomeIcon icon={faFilter} />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {props.results.length === 0 && <Typography>{t("noResults")}</Typography>}
    </Stack>
  )
}

export default RankedStreamerResults
