import RoleGuardedRoute from "components/RoleGuardedRoute"
import TaiyoroDataProvider from "components/Taiyoro/TaiyoroDataProvider"
import { UserRole } from "models/UserManagement"
import AutoScannedCollectionMetrics from "pages/Taiyoro/Analytics/autoScannedCollectionMetrics"
import DatePlatformClipsList from "pages/Taiyoro/Analytics/datePlatformClipsDisplay"
import DiscordReport from "pages/Taiyoro/Analytics/discordReport"
import EventsPlatformAnalyticsView from "pages/Taiyoro/Analytics/eventReports"
import EventTimeline from "pages/Taiyoro/Analytics/eventTimeline"
import EventTimelinePdf from "pages/Taiyoro/Analytics/eventTimelinePdf"
import { HeatMapCSVDownloadPage } from "pages/Taiyoro/Analytics/heatMapCSVDownload"
import DatePlatformStreamTimeline from "pages/Taiyoro/Analytics/streamTimeline"
import DatePlatformStreamTimelinePdf from "pages/Taiyoro/Analytics/streamTimelinePdf"
import ArticlesPage from "pages/Taiyoro/Articles"
import { EventDateBracketsPage } from "pages/Taiyoro/Brackets/event-date-brackets"
import { BracketsPage } from "pages/Taiyoro/Brackets/list"
import CalendarEmbed from "pages/Taiyoro/CalendarEmbed"
import ChannelRepair from "pages/Taiyoro/ChannelRepair"
import EventsPlatformEventsEdit from "pages/Taiyoro/Events/edit"
import EventsPlatformEventsList from "pages/Taiyoro/Events/list"
import EventSummaryPage from "pages/Taiyoro/Events/summary"
import { FeaturedEventsPage } from "pages/Taiyoro/FeaturedEvents"
import { GeoGuessrPage } from "pages/Taiyoro/GeoGuessr"
import { LJL2025ResultsEntryPage } from "pages/Taiyoro/LJL/2025"
import EventsPlatformMetaCasters from "pages/Taiyoro/Meta/casters"
import EventsPlatformMetaGameGroups from "pages/Taiyoro/Meta/gameGroups"
import EventsPlatformMetaGames from "pages/Taiyoro/Meta/games"
import EventsPlatformMetaOrganizers from "pages/Taiyoro/Meta/organizers"
import EventsPlatformMetaPlacements from "pages/Taiyoro/Meta/placements"
import EventsPlatformMetaPlatforms from "pages/Taiyoro/Meta/platforms"
import EventsPlatformMetaPlayers from "pages/Taiyoro/Meta/players"
import EventsPlatformMetaProducer from "pages/Taiyoro/Meta/producers"
import EventsPlatformMetaSponsors from "pages/Taiyoro/Meta/sponsors"
import EventsPlatformMetaTags from "pages/Taiyoro/Meta/tags"
import EventsPlatformMetaTeamOrganizations from "pages/Taiyoro/Meta/teamOrganizations"
import EventsPlatformMetaTeams from "pages/Taiyoro/Meta/teams"
import EventsPlatformMetaVenues from "pages/Taiyoro/Meta/venues"
import PickemSettingsPage from "pages/Taiyoro/Pickem"
import PostDetailsPage from "pages/Taiyoro/Posts/details"
import PostsListPage from "pages/Taiyoro/Posts/list"
import { Switch } from "react-router-dom"
import { ADMIN_ROLES, ANALYTICS_ROLES, ALL_ROLES } from "utils/roles"

const TaiyoroManagement = () => {
  return (
    <TaiyoroDataProvider>
      <Switch>
        <RoleGuardedRoute
          exact
          path="/taiyoro/event/list"
          component={EventsPlatformEventsList}
          allowedRoles={ALL_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/event/:eventId/edit"
          component={EventsPlatformEventsEdit}
          allowedRoles={[
            UserRole.ADMIN,
            UserRole.EDITOR,
            UserRole.PUBLISHER,
            UserRole.PRODUCER,
            UserRole.ANALYST,
          ]}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/event/:eventId"
          component={EventSummaryPage}
          allowedRoles={ALL_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/pickem/:eventId"
          component={PickemSettingsPage}
          allowedRoles={ALL_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/articles"
          component={ArticlesPage}
          allowedRoles={ADMIN_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/post"
          component={PostsListPage}
          allowedRoles={ADMIN_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/post/:postId"
          component={PostDetailsPage}
          allowedRoles={ADMIN_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/meta/casters"
          component={EventsPlatformMetaCasters}
          allowedRoles={ALL_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/meta/games"
          component={EventsPlatformMetaGames}
          allowedRoles={ALL_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/meta/game-groups"
          component={EventsPlatformMetaGameGroups}
          allowedRoles={ALL_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/meta/organizers"
          component={EventsPlatformMetaOrganizers}
          allowedRoles={ALL_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/meta/platforms"
          component={EventsPlatformMetaPlatforms}
          allowedRoles={ALL_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/meta/producers"
          component={EventsPlatformMetaProducer}
          allowedRoles={ALL_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/meta/placements"
          component={EventsPlatformMetaPlacements}
          allowedRoles={ALL_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/meta/players"
          component={EventsPlatformMetaPlayers}
          allowedRoles={ALL_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/meta/sponsors"
          component={EventsPlatformMetaSponsors}
          allowedRoles={ALL_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/meta/tags"
          component={EventsPlatformMetaTags}
          allowedRoles={ALL_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/meta/teams"
          component={EventsPlatformMetaTeams}
          allowedRoles={ALL_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/meta/team-organizations"
          component={EventsPlatformMetaTeamOrganizations}
          allowedRoles={ALL_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/meta/venues"
          component={EventsPlatformMetaVenues}
          allowedRoles={ALL_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/metrics/:eventId/:datePlatformId/view"
          component={DatePlatformStreamTimeline}
          allowedRoles={ALL_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/metrics/:eventId/:datePlatformId/viewPdf"
          component={DatePlatformStreamTimelinePdf}
          allowedRoles={ALL_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/metrics/:eventId/:datePlatformId/clips"
          component={DatePlatformClipsList}
          allowedRoles={ALL_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/metrics/:eventId/:dateId/autoScannedCollectionMetrics"
          component={AutoScannedCollectionMetrics}
          allowedRoles={ALL_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/metrics/:eventId/timeline"
          component={EventTimeline}
          allowedRoles={ALL_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/metrics/:eventId/timelinePdf/:page"
          component={EventTimelinePdf}
          allowedRoles={ALL_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/analytics/view"
          component={EventsPlatformAnalyticsView}
          allowedRoles={ANALYTICS_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/analytics/heatmap"
          component={HeatMapCSVDownloadPage}
          allowedRoles={ANALYTICS_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/discordReport"
          component={DiscordReport}
          allowedRoles={ANALYTICS_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/channel-repair"
          component={ChannelRepair}
          allowedRoles={ADMIN_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/calendar-embed"
          component={CalendarEmbed}
          allowedRoles={ADMIN_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/featured-events"
          component={FeaturedEventsPage}
          allowedRoles={ADMIN_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/brackets"
          component={BracketsPage}
          allowedRoles={ADMIN_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/:eventId/:dateId/brackets"
          component={EventDateBracketsPage}
          allowedRoles={ADMIN_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/ljl/2025"
          component={LJL2025ResultsEntryPage}
          allowedRoles={ADMIN_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/taiyoro/geoguessr"
          component={GeoGuessrPage}
          allowedRoles={ADMIN_ROLES}
        />
      </Switch>
    </TaiyoroDataProvider>
  )
}

export default TaiyoroManagement
