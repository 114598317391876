import { type Dispatch, type SetStateAction, createContext } from "react"

import type Event from "models/Taiyoro/event"
import { type PostListItem } from "services/Taiyoro/posts"

export type GeoGuessrTab = "news" | "events"

export type GeoGuessrSettings = {
  events: Array<Pick<Event, "id" | "name">>
  news: Array<Pick<PostListItem, "id" | "title" | "titleJa">>
}

export const SETTINGS_DEFAULT_VALUES: GeoGuessrSettings = {
  events: [],
  news: [],
}

type GeoGuessrContextType = {
  loading: boolean
  tab: GeoGuessrTab
  setTab: Dispatch<SetStateAction<GeoGuessrTab>>
  settings: GeoGuessrSettings
  save: () => void
  viewDraft: () => void
  modifiedSettings: GeoGuessrSettings
  setModifiedSettings: Dispatch<SetStateAction<GeoGuessrSettings>>
}

export const GeoGuessrContext = createContext<GeoGuessrContextType>({
  loading: false,
  tab: "news",
  setTab: () => undefined,
  settings: SETTINGS_DEFAULT_VALUES,
  save: () => undefined,
  viewDraft: () => undefined,
  modifiedSettings: SETTINGS_DEFAULT_VALUES,
  setModifiedSettings: () => undefined,
})
