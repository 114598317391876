

import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import { type ChangeEvent, useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXTwitter } from '@fortawesome/free-brands-svg-icons'
import { faXmark, faTriangleExclamation, faCheck } from '@fortawesome/pro-solid-svg-icons'
import { type Theme, styled } from '@mui/material/styles'
import { Avatar, Box, Typography } from '@mui/material'
import twttr from 'twitter-text'
import type Event from 'models/Taiyoro/event'
import { type Moment } from 'moment'
import { postEventTweet } from 'services/Taiyoro/event'
import { StyleCircularProgress } from 'components/StyleMaterialUI'
import { NotificationContext } from 'contexts/notification'
import { useTranslation } from 'react-i18next'
import type Game from 'models/Taiyoro/Meta/Game'
import { localisedLabel } from 'utils/i18n'
import { TAIYORO_DOMAIN } from 'utils/taiyoro'

const StyledBox = styled(Box)(({ theme }: { theme: Theme }) => ({
    position: 'absolute',
    left: 'calc(50vw - (580px / 2))',
    top: 'calc(50vh - (566.5px / 2))',
    width: '580px',
    backgroundColor: theme.palette.background.default,
    border: '1px solid #191A1C',
    padding: theme.spacing(4)
}))

const StyledModalBody = styled(Box)(() => ({
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}))

const StyledTwitterIcon = styled(FontAwesomeIcon)(({ theme }: { theme: Theme }) => ({
    backgroundColor: theme.palette.common.white,
    color: theme.palette.background.default,
    padding: theme.spacing(.5),
    borderRadius: '2px'

}))

const StyledPostToButton = styled(Button)(({ theme }: { theme: Theme }) => ({
    width: '150px',
    marginBottom: theme.spacing(2),
    backgroundColor: '#1F1F1F',
    '&:hover': {
        backgroundColor: '#546AB8',
    },
    '&.Mui-disabled': {
        backgroundColor: '#202125',
    },
    '&.Mui-disabled svg': {
        backgroundColor: '#717171',
    },
    '&.Mui-disabled > p': {
        color: '#717171',
    }
}))

const StyledPostOnButton = styled(Button)(({ theme }: { theme: Theme }) => ({
    width: '150px',
    marginBottom: theme.spacing(2),
    backgroundColor: '#002A05',
    position: 'relative',
    '&:hover': {
        backgroundColor: '#056210',
    },
    '&.Mui-disabled': {
        backgroundColor: '#202125',
    },
    '&.Mui-disabled svg': {
        backgroundColor: '#717171',
    },
    '&.Mui-disabled > p': {
        color: '#717171',
    },
}))

const StyledButtonTypography = styled(Typography)(({ theme }: { theme: Theme }) => ({
    textTransform: 'capitalize',
    padding: `${theme.spacing(.5)} ${theme.spacing(1)}`,
    color: theme.palette.common.white,
}))

const StyledClose = styled(Box)(({ theme }: { theme: Theme }) => ({
    position: 'absolute',
    right: '0px',
    top: theme.spacing(-4),
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    fontSize: '24px',
    alignItems: 'center',
    cursor: 'pointer',
    fontWeight: theme.typography.fontWeightBold,
    'svg': {
        color: theme.palette.common.white,
        fontSize: theme.typography.h2
    },
}))

const StyledAvatar = styled(Avatar)(({ theme }: { theme: Theme }) => ({
    margin: '0 auto',
    position: 'absolute',
    left: 'calc((75px + 20px) / 2)',
    marginTop: theme.spacing(2)
}))

const StyledModalTitle = styled(Box)(({ theme }: { theme: Theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
}))

const StyledTextarea = styled('textarea')(({ theme }: { theme: Theme }) => ({
    width: '100%',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    fontSize: theme.spacing(2),
    fontFamily: 'inherit',
    outline: 'none',
    resize: 'none',
    height: '400px',
    paddingLeft: '75px',
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '&:focus': {
        borderColor: theme.palette.primary.main,
        boxShadow: `0 0 0 1px ${theme.palette.primary.light}`,
        backgroundColor: '#21242B'
    },
}))

const StyledButtonPostToX = styled(Button)(() => ({
    backgroundColor: '#6FFC0F',
    color: '#121212',
    textTransform: 'capitalize',
    width: '176px',
    '&:hover': {
        backgroundColor: '#24A147',
    },
    '&.Mui-disabled': {
        color: '#717171',
        backgroundColor: '#202125',
    }
}))

const StyledModalFooter = styled(Box)(({ theme }: { theme: Theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    justifyContent: ' flex-end'
}))

const StyledWarning = styled(Box)(({ theme }: { theme: Theme }) => ({
    paddingRight: theme.spacing(2),
}))

const StyledCancelButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#21242B',
    borderRadius: '4px',
    width: '120px',
    padding: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: theme.palette.common.white,
    textTransform: 'capitalize',
    '&:hover': {
        backgroundColor: '#343841',
    },
}))

const StyledYesButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#79FB0B',
    borderRadius: '4px',
    width: '120px',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: theme.palette.common.black,
    textTransform: 'capitalize',
    '&:hover': {
        backgroundColor: '#00D000',
    },
}))

const StyledExclaimationMark = styled(FontAwesomeIcon)(({ theme }) => ({
    fontSize: theme.typography.h4.fontSize,
    marginBottom: theme.spacing(2)
}))

const PostToXLabel = ({ handleOpen }) => {
    const { t } = useTranslation(['taiyoro'])
    return (
        <StyledPostToButton onClick={handleOpen}>
            <StyledTwitterIcon icon={faXTwitter} />
            <StyledButtonTypography>{t('postToX.title')}</StyledButtonTypography>
        </StyledPostToButton>
    )
}

const PostAgainToXLabel = ({ handleOpen }) => {
    const [hovered, setHovered] = useState(false)

    return (
        <StyledPostOnButton
            onClick={handleOpen}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <FontAwesomeIcon icon={faCheck} style={{ marginRight: 8, color: '#24A147' }} />
            <StyledButtonTypography>{hovered ? 'Post Again?' : 'Posted on X'}</StyledButtonTypography>
        </StyledPostOnButton>
    )
}

export const PostToX = ({ model, games }: { model: Event, games: Array<Game> }) => {
    const { t } = useTranslation(['taiyoro'])
    const { setNotification } = useContext(NotificationContext)
    const [characterCount, setCharacterCount] = useState(0)
    const [openModal, setOpenModal] = useState(false)
    const [isValid, setIsValid] = useState(false)
    const [textDescription, setTextDescription] = useState('')
    const [confirmPost, setConfirmPost] = useState(false)
    const [loading, setLoading] = useState(false)

    const startDateFirstRecord = model.dates[0]
    const lastDateLastRecord = model.dates[model.dates.length - 1]
    const startDateTime = startDateFirstRecord.startTime
    const endDateTime = lastDateLastRecord.startTime
    const eventName = startDateFirstRecord.name

    const eventTaiyoroUrl = `${TAIYORO_DOMAIN}/event/${model.id}/${model.urlSlug}`

    const uniqueGames = model.dates
        .filter(date => date.game)
        .filter((date, index, self) =>
            index === self.findIndex(d => d.game === date.game)
        )
        .map(date => {
            const matchingGame = games.find(g => g.id === date.game)
            return localisedLabel(matchingGame) as string
        })

    const formattedGames = uniqueGames.length > 1 ? uniqueGames.join(", ") : uniqueGames[0] || "";

    const formattedDate = (datetime: Moment) => datetime.locale('ja').format('YYYY年M月D日')

    const handleOpen = () => setOpenModal(true)
    const handleClose = () => setOpenModal(false)
    const handleTextarea = (e: ChangeEvent<HTMLTextAreaElement>) => setTextDescription(e.target.value)

    const postToXHandle = async () => {
        setLoading(true)
        try {
            await postEventTweet(model.id!, textDescription)
            setTimeout(() => {
                setLoading(false)
                setNotification({
                    message: t('postToX.postSuccess'),
                    severity: 'success',
                })
                handleClose()
                setConfirmPost(false)
            }, 1000)

        } catch (err) {
            setTimeout(() => {
                setLoading(false)
                setNotification({
                    message: t('postToX.postError'),
                    severity: 'error',
                })
                handleClose()
                setConfirmPost(false)
            }, 1000)
        }
    }

    useEffect(() => {
        const isSameDate = startDateTime.isSame(endDateTime, 'day')
        const tweet = `${model.name}情報更新‼️

${formattedDate(startDateTime)}${!isSameDate ? ' - ' + formattedDate(endDateTime) : ''}に${formattedGames}のイベントが開催予定🎉

▼イベント情報はTAIYOROカレンダーをチェック▼
${eventTaiyoroUrl}

${model.hashtag ? '#' + model.hashtag + ' ' : ''}${model.twitterHandle ? '@' + model.twitterHandle : ''}`

        if (textDescription.length === 0 || textDescription !== tweet) setTextDescription(tweet)
    }, [model, startDateTime, endDateTime, eventName])

    useEffect(() => {
        const parsedTweet = twttr.parseTweet(textDescription)
        setCharacterCount(280 - parsedTweet.weightedLength)
        if (textDescription.length === 0) {
            setIsValid(false)
            return
        }
        setIsValid(parsedTweet.valid)
    }, [textDescription])

    return (
        <>
            {model.didTwitterPost === 1 ? (
                <PostAgainToXLabel handleOpen={handleOpen} />
            ) : (
                <PostToXLabel handleOpen={handleOpen} />
            )}

            <Modal open={openModal} onClose={handleClose}>
                <StyledBox>
                    <StyledClose onClick={handleClose}>
                        <FontAwesomeIcon icon={faXmark} />
                    </StyledClose>

                    {!confirmPost && (
                        <>
                            <StyledModalTitle>
                                <StyledTwitterIcon icon={faXTwitter} />
                                <StyledButtonTypography>{t('postToX.title')}</StyledButtonTypography>
                            </StyledModalTitle>

                            <StyledModalBody>
                                <StyledAvatar src='/images/taiyoro-logo.png' />
                                <StyledTextarea onChange={handleTextarea} value={textDescription} />
                            </StyledModalBody>

                            <StyledModalFooter>
                                <StyledWarning style={characterCount < 0 ? { color: '#DA1E28' } : {}}>
                                    {characterCount < 0 && t('postToX.charcterLimit')} {characterCount}
                                </StyledWarning>

                                <StyledButtonPostToX onClick={() => setConfirmPost(true)} disabled={!isValid}>
                                    {t('postToX.title')}
                                </StyledButtonPostToX>
                            </StyledModalFooter>
                        </>
                    )}

                    {confirmPost && (
                        <>
                            <StyledModalBody>
                                <StyledExclaimationMark icon={faTriangleExclamation} />
                                <Typography>{t('postToX.postConfirmation')}</Typography>
                                <Box>
                                    {!loading && (
                                        <StyledCancelButton onClick={() => setConfirmPost(false)}>
                                            {t('postToX.cancel')}
                                        </StyledCancelButton>
                                    )}
                                    <StyledYesButton onClick={() => void postToXHandle()}>
                                        {loading ? <StyleCircularProgress style={{ color: 'black' }} /> : 'Yes'}
                                    </StyledYesButton>
                                </Box>
                            </StyledModalBody>
                        </>
                    )}
                </StyledBox>
            </Modal>
        </>
    )
}

