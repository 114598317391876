import { useState } from "react"

import { Dialog, DialogContent, DialogActions, Button } from "@mui/material"
import { PostsAutocomplete } from "components/PostsAutocomplete"
import { t } from "i18next"
import { type PostListItem } from "services/Taiyoro/posts"

import { type PostMinimumData } from "./news"

interface Props {
  open: boolean
  onPostSelected: (post: PostMinimumData) => void
  onClose: () => void
}

export const PostSelectionDialog = (props: Props) => {
  const [post, setPost] = useState<PostListItem | null>(null)

  const handleSave = () => {
    if (!post) {
      props.onClose()
      return
    }
    props.onPostSelected(post)
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth
    >
      <DialogContent dividers>
        <PostsAutocomplete
          postId={null}
          onPostChanged={setPost}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>{t("common:actions.cancel")}</Button>
        <Button onClick={handleSave}>{t("common:actions.save")}</Button>
      </DialogActions>
    </Dialog>
  )
}
