import { Stack } from "@mui/material"
import { ControlBar } from "components/Taiyoro/GeoGuessr/ControlBar"
import { DataProvider } from "components/Taiyoro/GeoGuessr/DataProvider"
import { EventsTable } from "components/Taiyoro/GeoGuessr/EventsTable"
import { NewsTable } from "components/Taiyoro/GeoGuessr/NewsTable"

export const GeoGuessrPage = () => {
  return (
    <DataProvider>
      <Stack gap={2}>
        <ControlBar />
        <NewsTable />
        <EventsTable />
      </Stack>
    </DataProvider>
  )
}
