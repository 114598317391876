import React, { useContext, useEffect, useState } from "react"

import { Box, FormControl, InputLabel, MenuItem, Select, styled, Typography } from "@mui/material"
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid"
import { PickemSettingsContext } from "contexts/pickem"
import { Player, Team } from "models/Taiyoro/Meta/Placement"
import { useTranslation } from "react-i18next"
import { DailyPickemStats } from "services/Taiyoro/Pickem/analytics"
import { FreetextAnswer } from "services/Taiyoro/Pickem/daily"
import { UTCDateInJST } from "utils/tools"

import { StackedChart } from "./StackedChart"

const TableWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  marginTop: theme.spacing(2),
  "& .MuiDataGrid-root .MuiDataGrid-cell": {
    whiteSpace: "normal !important",
    wordWrap: " break-word !important",
  },
}))

export const DailyAnalyticsTable = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation("taiyoro")
  const { dailyAnalytics, eventState, dailyPickemList } = useContext(PickemSettingsContext)

  const [list, setList] = useState<Array<DailyPickemStats>>([])
  const [fullList, setFullList] = useState<Array<DailyPickemStats>>([])
  const [teamsList, setTeamsList] = useState<Array<Team>>([])
  const [playersList, setPlayersList] = useState<Array<Player>>([])
  const [freeTextList, setFreeTextList] = useState<Array<FreetextAnswer>>([])
  const [filterDate, setFilterDate] = useState("")
  const [dateOptions, setDateOptions] = useState<string[]>([])

  const getFilteredAnswers = () => {
    const filteredTeamsList = teamsList.map((team) => ({ id: team.id, name: team.name, nameJa: team.nameJa }))
    const filteredPlayersList = playersList.map((player) => ({
      id: player.id,
      name: player.name,
      nameJa: player.nameJa,
    }))
    const filteredFreeTextList = freeTextList.map((freetext) => ({
      id: freetext.id,
      name: freetext.content,
      nameJa: freetext.contentJa,
    }))
    return [...filteredTeamsList, ...filteredPlayersList, ...filteredFreeTextList]
  }

  const renderCells = {
    default: (params) => <Typography>{params.value}</Typography>,
    date: (params) => <Typography>{params.value.name}</Typography>,
    prompt: (params) => {
      let name = params.row.promptJa || params.row.prompt
      if (language === "en") name = params.row.prompt || params.row.promptJa
      return <Typography>{name}</Typography>
    },
    correctAnswer: (params) => {
      const correctAnswer = params.value
      const answerList = getFilteredAnswers()
      if (!answerList || answerList.length === 0 || !correctAnswer) return
      const foundAnswer = answerList.find((item) => item.id === correctAnswer)
      if (!foundAnswer) return
      let answerName = foundAnswer?.nameJa || foundAnswer.name
      if (language === "en") answerName = foundAnswer.name || foundAnswer?.nameJa
      return <Typography>{answerName}</Typography>
    },
    pickedAnswer: (params) => {
      const answers = params.row.answers
      const correctAnswer = params.row.correctAnswer
      return (
        <StackedChart
          answers={answers}
          correctAnswerId={correctAnswer}
          answersList={getFilteredAnswers()}
        />
      )
    },
    totalParticipants: (params) => {
      const answers = params.row.answers
      const totalUsersSum = answers.reduce((sum, answer) => sum + answer.totalUsers, 0)
      return <Typography>{totalUsersSum}</Typography>
    },
  }

  const customDateOperator = {
    label: "contains",
    value: "contains",
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) return null
      return (row) => {
        const rowDate = row.date
        if (filterItem.value && rowDate) {
          return rowDate === filterItem.value
        }
        return true
      }
    },
    InputComponent: (props) => {
      const handleChange = (event) => {
        const selectedDate = event.target.value
        setFilterDate(selectedDate)

        if (!selectedDate) {
          setList(fullList)
        } else {
          const filteredList = fullList.filter((row) => row.date.name === selectedDate)
          setList(filteredList)
        }
      }

      return (
        <FormControl
          variant="standard"
          fullWidth
        >
          <InputLabel>Date</InputLabel>
          <Select
            value={filterDate || ""}
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {dateOptions.map((date) => (
              <MenuItem
                key={date}
                value={date}
              >
                {date}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    },
    InputComponentProps: { type: "string" },
  }

  const columns = Object.keys(t("eventPickem.dailyAnalytics.tableHeaders", { returnObjects: true })).map(
    (key) => {
      let columnConfig: GridColDef = {
        field: key,
        sortable: true,
        headerName: t(`eventPickem.dailyAnalytics.tableHeaders.${key}`),
        renderCell: renderCells[key] || renderCells["default"],
        valueGetter: (params) => {
          if (key === "totalParticipants") {
            const answers = params.row.answers
            const totalUsersSum = answers.reduce((sum, answer) => sum + answer.totalUsers, 0)
            return totalUsersSum
          } else if (key === "correctAnswer") {
            const correctAnswer = params.value
            const answerList = getFilteredAnswers()
            const foundAnswer = answerList.find((item) => item.id === correctAnswer)
            if (!foundAnswer) return
            let answerName = foundAnswer?.nameJa || foundAnswer.name
            if (language === "en") answerName = foundAnswer.name || foundAnswer?.nameJa
            return answerName
          } else {
            return params.row[key]
          }
        },
        flex: (() => {
          if (key === "pickedAnswer") return 8
          else if (key === "priority" || key === "groupPriority") return 1
          else return 1.5
        })(),
      }

      if (key === "date") {
        columnConfig = {
          ...columnConfig,
          filterOperators: [customDateOperator],
        }
      }

      return columnConfig
    }
  )

  useEffect(() => {
    const players = eventState?.games
      .flatMap((game) => game.participants.players.map((playerData) => playerData.player))
      .filter((player) => !!player)
    const teams = eventState?.games
      .flatMap((game) => game.participants.teams.map((teamData) => teamData.team))
      .filter((team) => !!team)
    const freeText = dailyPickemList
      .filter((pickem) => pickem.answerOptions.type === "freetext")
      .flatMap((pickem) => pickem.answerOptions.data as Array<FreetextAnswer>)

    if (!teams || !players || !freeText) return

    setTeamsList(teams)
    setPlayersList(players)
    setFreeTextList(freeText)

    const filteredDailyAnalytics = dailyAnalytics
      .map((dailyAnalytic) => {
        const { id, prompt, promptJa, priority, groupPriority, correctAnswer, date, answers } = dailyAnalytic
        return { id, prompt, promptJa, priority, groupPriority, correctAnswer, date, answers }
      })
      .sort((a, b) => {
        const dateA = UTCDateInJST(a.date.startDatetime)
        const dateB = UTCDateInJST(b.date.startDatetime)
        if (!dateA.isSame(dateB)) return dateA.isBefore(dateB) ? -1 : 1
        if (a.priority !== b.priority) return a.priority < b.priority ? -1 : 1
        return a.groupPriority < b.groupPriority ? -1 : 1
      }) as Array<DailyPickemStats>

    setFullList(filteredDailyAnalytics)
    setList(filteredDailyAnalytics)

    const uniqueDates = [...new Set(filteredDailyAnalytics.map((item) => item.date.name))]
    setDateOptions(uniqueDates)
  }, [dailyPickemList, eventState, dailyAnalytics, language])

  return (
    <TableWrapper>
      <DataGrid
        sx={{ height: "calc(100% - 85px)" }}
        columns={columns}
        rows={list}
        disableVirtualization
        autoHeight={true}
        rowHeight={100}
        hideFooter
        density="comfortable"
        components={{
          Toolbar: GridToolbar,
        }}
        onFilterModelChange={(filterModel) => {
          setFilterDate("")
          setList(fullList)
        }}
      />
    </TableWrapper>
  )
}
