import { useContext, useEffect, useState } from "react"

import { faExternalLink } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Card, CardContent, Link, Stack, Typography } from "@mui/material"
import { NotificationContext } from "contexts/notification"
import type Game from "models/Taiyoro/Meta/Game"
import type Language from "models/Taiyoro/Meta/Language"
import type Organizer from "models/Taiyoro/Meta/Organizer"
import type Platform from "models/Taiyoro/Meta/Platform"
import type Sponsor from "models/Taiyoro/Meta/Sponsor"
import type { Tag } from "models/Taiyoro/Meta/Tag"
import type Venue from "models/Taiyoro/Meta/Venue"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useLocalStorage } from "react-use"
import { TAIYORO_DOMAIN, taiyoroRatingPercentileToScore } from "utils/taiyoro"

import LoadingBox from "../../../components/LoadingBox"
import DeleteEventDialog from "../../../components/Taiyoro/DeleteEventDialog"
import EventInfo from "../../../components/Taiyoro/EventList/EventInfo"
import { PublishedState } from "../../../models/Taiyoro/event"
import type Event from "../../../models/Taiyoro/event"
import { fetchEvent } from "../../../services/Taiyoro/event"
import { fetchGames } from "../../../services/Taiyoro/games"
import { fetchLanguages } from "../../../services/Taiyoro/languages"
import { fetchOrganizers } from "../../../services/Taiyoro/organizers"
import { fetchPlatforms } from "../../../services/Taiyoro/platforms"
import { fetchSponsors } from "../../../services/Taiyoro/sponsors"
import { fetchTags } from "../../../services/Taiyoro/tags"
import { fetchVenues } from "../../../services/Taiyoro/venues"
import { localisedLabel } from "../../../utils/i18n"

const convertAnalytics = (analytics: any) => {
  if (
    !analytics ||
    (!analytics.viewsLiveConcurrentAverage &&
      !analytics.viewsLiveConcurrentPeak &&
      !analytics.viewsLiveMinutesWatched)
  ) {
    return null
  }

  return {
    viewsLiveConcurrentAverage: Math.round(analytics.viewsLiveConcurrentAverage),
    viewsLiveConcurrentPeak: Math.round(analytics.viewsLiveConcurrentPeak),
    viewsLiveMinutesWatched: Math.round(Number(analytics.viewsLiveMinutesWatched)),
  }
}

type AnalyticsType = {
  viewsLiveConcurrentAverage: number
  viewsLiveConcurrentPeak: number
  viewsLiveMinutesWatched: number
}

export type RecentlyViewedEventItem = {
  eventName: string
  eventId: string
  viewed: string
}

export const RECENTLY_VIEWED_EVENTS_KEY = "recentlyViewedEvents"

const EventSummaryPage = () => {
  const [eventState, setEventState] = useState<null | Event>(null)
  const [errorState, setErrorState] = useState(false)
  const [tagsState, setTagsState] = useState<Tag[] | null>([])
  const [organizersState, setOrganizersState] = useState<Organizer[] | null>([])
  const [sponsorsState, setSponsorsState] = useState<Sponsor[] | null>([])
  const [gamesState, setGamesState] = useState<Game[]>([])
  const [venuesState, setVenuesState] = useState<Venue[] | null>([])
  const [platformsState, setPlatformsState] = useState<Platform[] | null>([])
  const [languagesState, setLanguagesState] = useState<Language[]>([])
  const [showDeleteState, setShowDeleteState] = useState(false)
  const [analyticsState, setAnalyticsState] = useState<AnalyticsType | null>(null)
  const [loadingState, setLoadingState] = useState(true)
  const [, setRecentlyViewedEvents] = useLocalStorage<Array<RecentlyViewedEventItem>>(
    RECENTLY_VIEWED_EVENTS_KEY,
    []
  )

  const { setNotification } = useContext(NotificationContext)
  const { eventId } = useParams<{ eventId: string }>()
  const { t } = useTranslation(["taiyoro", "common"])

  const load = async () => {
    try {
      const [event, games, tags, organizers, sponsors, venues, platforms, languages] = await Promise.all([
        fetchEvent(eventId),
        fetchGames(),
        fetchTags(),
        fetchOrganizers(),
        fetchSponsors(),
        fetchVenues(),
        fetchPlatforms(),
        fetchLanguages(),
      ])
      setEventState(event)
      setGamesState(games)
      setTagsState(tags)
      setOrganizersState(organizers)
      setSponsorsState(sponsors)
      setVenuesState(venues)
      setPlatformsState(platforms)
      setLanguagesState(languages)
      setAnalyticsState(convertAnalytics(event.analytics))
      addToRecentlyViewed(event)
    } catch {
      setErrorState(true)
      setNotification({
        message: t("common:errorLoadingData"),
        severity: "error",
      })
    } finally {
      setLoadingState(false)
    }
  }

  const addToRecentlyViewed = (event: Event) => {
    setRecentlyViewedEvents((currentRecentlyViewedEvents) => {
      const newRecentlyViewedEvent: RecentlyViewedEventItem = {
        eventId: event.id!,
        eventName: event.name,
        viewed: moment().utc().toISOString(),
      }
      return [
        newRecentlyViewedEvent,
        ...(currentRecentlyViewedEvents
          ? currentRecentlyViewedEvents
              .filter((currentEvent) => currentEvent.eventId !== event.id)
              .slice(0, 4)
          : ([] as Array<RecentlyViewedEventItem>)),
      ]
    })
  }

  useEffect(() => {
    eventId && load()
    // eslint-disable-next-line
  }, [eventId])

  const getNameOfEntityFromEntity = (entityList: Organizer[] | Sponsor[], entityId: any) => {
    const mappedEntity = entityList.find((e) => e.id === entityId)
    if (!mappedEntity) {
      return ""
    }
    return localisedLabel(mappedEntity)
  }

  return (
    <>
      <Box p={1}>
        {loadingState && <LoadingBox />}
        {!loadingState && !errorState && eventState && (
          <>
            <Typography
              variant="h4"
              fontWeight="700"
              mb={1}
            >
              {eventState.name}
            </Typography>
            {eventState.published === PublishedState.Published && (
              <Link
                display="block"
                my={1}
                href={`${TAIYORO_DOMAIN}/event/${eventState.id}/${eventState.urlSlug}`}
                target="_blank"
              >
                <Stack
                  direction="row"
                  gap={1}
                  alignItems="center"
                >
                  <img
                    src="/images/taiyoro-logo.png"
                    width="20px"
                    height="20px"
                    alt=""
                  />
                  <Typography
                    component="span"
                    variant="body2"
                  >{`/${eventState.id}/${eventState.urlSlug}`}</Typography>
                  <FontAwesomeIcon icon={faExternalLink} />
                </Stack>
              </Link>
            )}
            {analyticsState && (
              <Box mb={1}>
                <Stack
                  direction="row"
                  width="100%"
                  gap={1}
                >
                  {analyticsState.viewsLiveConcurrentPeak && (
                    <Card sx={{ minWidth: 250 }}>
                      <CardContent>
                        <Typography
                          variant="h5"
                          component="div"
                        >
                          {t("list.summary.dateList.streamList.pcu")}
                        </Typography>
                        <Typography
                          sx={{ mb: 1.5 }}
                          color="text.secondary"
                        >
                          {analyticsState.viewsLiveConcurrentPeak.toLocaleString()}
                        </Typography>
                      </CardContent>
                    </Card>
                  )}
                  {analyticsState.viewsLiveConcurrentAverage && (
                    <Card sx={{ minWidth: 250 }}>
                      <CardContent>
                        <Typography
                          variant="h5"
                          component="div"
                        >
                          {t("list.summary.dateList.streamList.acu")}
                        </Typography>
                        <Typography
                          sx={{ mb: 1.5 }}
                          color="text.secondary"
                        >
                          {analyticsState.viewsLiveConcurrentAverage.toLocaleString()}
                        </Typography>
                      </CardContent>
                    </Card>
                  )}
                  {analyticsState.viewsLiveMinutesWatched && (
                    <Card sx={{ minWidth: 250 }}>
                      <CardContent>
                        <Typography
                          variant="h5"
                          component="div"
                        >
                          {t("list.summary.dateList.streamList.minsWatched")}
                        </Typography>
                        <Typography
                          sx={{ mb: 1.5 }}
                          color="text.secondary"
                        >
                          {analyticsState.viewsLiveMinutesWatched.toLocaleString()}
                        </Typography>
                      </CardContent>
                    </Card>
                  )}
                  <Card sx={{ minWidth: 250 }}>
                    <CardContent>
                      <Typography
                        variant="h5"
                        component="div"
                      >
                        {t(`analytics.results.TAIYORO_RATING`)}
                      </Typography>
                      <Typography
                        sx={{ mb: 1.5 }}
                        color="text.secondary"
                      >
                        {eventState.taiyoroRating && taiyoroRatingPercentileToScore(eventState.taiyoroRating)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Stack>
              </Box>
            )}
            {tagsState && organizersState && sponsorsState && venuesState && platformsState && (
              <EventInfo
                model={eventState}
                tagList={tagsState}
                organizerList={eventState.organizers.map((organizerId) =>
                  getNameOfEntityFromEntity(organizersState, organizerId)
                )}
                producerList={eventState.producers.map(localisedLabel)}
                sponsorList={eventState.sponsors.map((sponsor) =>
                  getNameOfEntityFromEntity(sponsorsState, sponsor.id)
                )}
                casterList={eventState.casters.map(localisedLabel)}
                venueList={venuesState}
                platformList={platformsState}
                languageList={languagesState}
                games={gamesState}
                onDelete={() => setShowDeleteState(true)}
              />
            )}
          </>
        )}
      </Box>
      {eventState && (
        <DeleteEventDialog
          open={showDeleteState}
          model={eventState}
          onClose={() => setShowDeleteState(false)}
        />
      )}
    </>
  )
}

export default EventSummaryPage
