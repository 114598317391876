import type React from "react"
import { createContext } from "react"

import type { Paginate } from "components/FrontEndPagination/helpers"
import { initialSettings } from "components/Taiyoro/Pickem/PickemProvider"
import type Event from "models/Taiyoro/event"
import { DailyPickemStats } from "services/Taiyoro/Pickem/analytics"
import type {
  DailyPickemAny,
  DailyPickemGroupAny,
  DailyPickemGroupModified,
  DailyPickemGroupNew,
  DailyPickemModified,
  DailyPickemNew,
} from "services/Taiyoro/Pickem/daily"
import type { PickemUser } from "services/Taiyoro/Pickem/participants"
import type { Settings } from "services/Taiyoro/Pickem/settings"

/* eslint-disable @typescript-eslint/no-empty-function */

export type PaginationContext = {
  paginateNumberOfRecords: number
  setPaginateNumberOfRecords: React.Dispatch<React.SetStateAction<number>>
  paginatePage: number
  setPaginatePage: React.Dispatch<React.SetStateAction<number>>
  paginatedListData: Paginate<PickemUser> | Array<never>
  setPaginatedListData: React.Dispatch<Paginate<PickemUser> | Array<never>>
}

const initialPaginationContext = {
  paginateNumberOfRecords: 100,
  setPaginateNumberOfRecords: () => 100,
  paginatePage: 1,
  setPaginatePage: () => 1,
  paginatedListData: [],
  setPaginatedListData: () => [],
}

type PickemContextType = {
  setSaving: (saving: boolean) => void
  loading: boolean
  eventId: string
  eventState: Event | undefined
  setEventState: (event: Event) => void
  settings: Settings
  setSettings: React.Dispatch<React.SetStateAction<Settings>>
  dailyPickemList: Array<DailyPickemAny>
  setDailyPickemList: (dailyPickemList: Array<DailyPickemAny>) => void
  dailyPickemGroupList: Array<DailyPickemGroupAny>
  setDailyPickemGroupList: (dailyPickemGroupList: Array<DailyPickemGroupAny>) => void
  participants: Array<PickemUser>
  setParticipants: (participants: Array<PickemUser>) => void
  dailyAnalytics: Array<DailyPickemStats>
  setDailyAnalytics: (dailyPickemStats: Array<DailyPickemStats>) => void
  refreshData: () => Promise<void>
} & PaginationContext

const initialPickemContext = {
  setSaving: () => undefined,
  loading: false,
  eventId: "",
  eventState: undefined,
  setEventState: () => undefined,
  settings: initialSettings,
  setSettings: () => undefined,
  dailyPickemList: [],
  setDailyPickemList: () => [],
  dailyPickemGroupList: [],
  setDailyPickemGroupList: () => [],
  participants: [],
  setParticipants: () => undefined,
  dailyAnalytics: [],
  setDailyAnalytics: () => undefined,
  refreshData: () => new Promise<void>(() => {}),

  ...initialPaginationContext,
}

export const PickemSettingsContext = createContext<PickemContextType>(initialPickemContext)

type PickemFunctionsContextType = {
  addGroup: (dailyPickem: DailyPickemGroupNew) => void
  updateGroup: (dailyPickem: DailyPickemGroupModified | DailyPickemGroupNew) => void
  editGroup: (groupId: string) => void
  deleteGroup: (groupId: string) => void
  restoreGroup: (groupId: string) => void
  addDailyPickem: (dailyPickem: DailyPickemNew) => void
  updateDailyPickem: (dailyPickem: DailyPickemModified | DailyPickemNew) => void
  editDailyPickem: (dailyPickemId: string) => void
  deleteDailyPickem: (dailyPickemId: string) => void
  restoreDailyPickem: (dailyPickemId: string) => void
  stageCorrectAnswer: (
    dailyPickemId: string,
    answer: {
      id: string
      content: string
      contentJa: string
    }
  ) => void
  stageAwardPoints: (dailyPickemId: string) => void
  moveGroupUp: (groupId: string) => void
  moveGroupDown: (groupId: string) => void
  movePickemUp: (pickemId: string) => void
  movePickemDown: (pickemId: string) => void
  togglePublic: (pickemId: string) => void
  toggleDraft: (pickemId: string) => void
  createNewDailyPickem: (pickemRecord: DailyPickemAny | null) => void
}

const initialPickemFunctionsContext = {
  addGroup: () => {},
  updateGroup: () => {},
  editGroup: () => {},
  deleteGroup: () => {},
  restoreGroup: () => {},
  addDailyPickem: () => {},
  updateDailyPickem: () => {},
  editDailyPickem: () => {},
  deleteDailyPickem: () => {},
  restoreDailyPickem: () => {},
  stageCorrectAnswer: () => {},
  stageAwardPoints: () => {},
  moveGroupUp: () => {},
  moveGroupDown: () => {},
  movePickemUp: () => {},
  movePickemDown: () => {},
  togglePublic: () => {},
  toggleDraft: () => {},
  createNewDailyPickem: () => {},
}

export const PickemFunctionsContext = createContext<PickemFunctionsContextType>(initialPickemFunctionsContext)

type PickemModalContextType = {
  setSelectedGroup: (group: DailyPickemGroupAny | null) => void
  setPrefilledGroupData: (data: { dateId?: string; priority?: number } | null) => void
  setIsGroupModalOpen: (open: boolean) => void
  setSelectedDailyPickem: (pickem: DailyPickemAny | null) => void
  setPrefilledData: (data: { dateId?: string; groupId?: string; priority?: number } | null) => void
  setIsPickemModalOpen: (open: boolean) => void
}

const initialPickemModalContext = {
  setSelectedGroup: () => {},
  setPrefilledGroupData: () => {},
  setIsGroupModalOpen: () => {},
  setSelectedDailyPickem: () => {},
  setPrefilledData: () => {},
  setIsPickemModalOpen: () => {},
}

export const PickemModalContext = createContext<PickemModalContextType>(initialPickemModalContext)
