
import { ID_TOKEN_KEY } from "utils/graphql"

const fetchEndpoint = process.env.REACT_APP_FUTAROKU_QUERYAPI ?? "https://webhook.dev.futaroku.gg/api"


export type LineItem = {
    title: string;
    vendor: string;
    properties: Array<{ OrderId?: string, name: string, value: string }> | undefined;
};

export type OrderInfo = {
    order_number: string;
    line_items: Array<LineItem>;
    timestamp: string
};

export type OrderInfoResponse = {
    message: string
    data: Array<OrderInfo>
}


export const fetchAllLJLSupportersList = async (): Promise<Array<OrderInfo>> => {
    const authHeader = { Authorization: `Bearer ${localStorage.getItem(ID_TOKEN_KEY)!}` }
    const response = await fetch(fetchEndpoint + "/ljl-supporters", { method: "GET", headers: authHeader })
    if (!response.ok) throw new Error(`Failed to fetch supporters: ${response.statusText}`)
    const result = await response.json() as OrderInfoResponse
    return result.data;
};



export const updateApprovedLJLSupportersList = async (list) => {
    const authHeader = { Authorization: `Bearer ${localStorage.getItem(ID_TOKEN_KEY)!}` }
    const response = await fetch(fetchEndpoint + "/ljl-approve-supporters", {
        method: "POST", headers: {
            'Content-Type': 'application/json',
            ...authHeader
        }, body: JSON.stringify({ list })
    })
    if (!response.ok) throw new Error(`Failed to fetch supporters: ${response.statusText}`)
    return response;
}

export type CachedNamesResponse = {
    message: string
    data: {
        OrderId: string
        names: string
    }
}

export type CachedSupporters = {
    list: {
        "0": Array<Record<string, string>>;
        "1": Array<Record<string, string>>;
    };
}

export const fetchCachedSupportersList = async () => {
    const authHeader = { Authorization: `Bearer ${localStorage.getItem(ID_TOKEN_KEY)!}` }
    const response = await fetch(fetchEndpoint + "/ljl-approve-supporters", { method: "GET", headers: authHeader })
    if (!response.ok) throw new Error(`Failed to fetch supporters: ${response.statusText}`)
    const result = await response.json() as CachedNamesResponse
    return result.data;
}