import React, { useContext, useState } from "react"

import { Groups as GroupsIcon, Download as DownloadIcon } from "@mui/icons-material/"
import { TabList, TabContext, TabPanel } from "@mui/lab"
import { Box, Button, Typography, Modal, Stack, Tab, styled } from "@mui/material"
import { PickemSettingsContext } from "contexts/pickem"
import { useTranslation } from "react-i18next"
import { convertAndDownloadBlobAsCSV } from "utils/tools"

import { DailyAnalyticsTable } from "./DailyAnalyticsTable"
import { ParticipantsTable } from "./ParticipantsTable"

export const TabWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, calc(-50% + 32px))",
  width: "90vw",
  maxHeight: "calc(100% - 64px - 64px)",
  overflow: "auto",
  backgroundColor: theme.palette.background.paper,
  border: "2px solid #000",
  boxShadow: "24",
  padding: theme.spacing(4),
}))

const Participants = () => {
  const { t } = useTranslation("taiyoro")
  const [tab, setTab] = useState("1")

  const [open, setOpen] = useState<boolean>(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const { participants, eventState } = useContext(PickemSettingsContext)

  const handleDownload = () => {
    const filename = `${eventState?.name ?? ""} ${t("eventPickem.pickem")} ${t("eventPickem.participants.participatingUsers")}`
    const blob = new Blob(
      [
        `${Object.values(t("eventPickem.participants.tableHeaders", { returnObjects: true })).join(",")}\n`,
        participants
          .map((participant) => {
            return Object.keys(t("eventPickem.participants.tableHeaders", { returnObjects: true }))
              .map((key) => `${participant[key] as string}`)
              .join(",")
          })
          .join("\n"),
      ],
      {
        type: "text/csvencoding:utf-8",
      }
    )

    convertAndDownloadBlobAsCSV(blob, filename)
  }

  const handleChange = (_, tabValue: string) => setTab(tabValue)

  return (
    <Box
      ml="auto"
      mr={0}
    >
      <Button
        component="label"
        variant="contained"
        endIcon={<GroupsIcon />}
        onClick={handleOpen}
      >
        {t("eventPickem.participants.showAnalytics")}
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-pickem-participants-heading"
        aria-describedby="modal-pickem-participants-body"
      >
        <TabContext value={tab}>
          <TabWrapper>
            <TabList
              onChange={handleChange}
              aria-label="analysis-tabs"
            >
              <Tab
                label={t("eventPickem.participants.showParticipatingUsers")}
                value="1"
              />
              <Tab
                label={t("eventPickem.participants.showAnalytics")}
                value="2"
              />
            </TabList>

            <TabPanel value="1">
              <Stack
                direction="row"
                justifyContent="space-between"
              >
                <Typography
                  id="modal-pickem-participants-counter"
                  alignItems={"center"}
                  display={"flex"}
                >
                  {t("edit.games.participantCount")}: {participants.length || 0}
                </Typography>

                <Button
                  component="label"
                  variant="contained"
                  endIcon={<DownloadIcon />}
                  onClick={handleDownload}
                >
                  {t("analytics.form.downloadCsv")}
                </Button>
              </Stack>

              <Box
                id="modal-pickem-participants-body"
                sx={{ mt: 2 }}
              >
                <ParticipantsTable />
              </Box>
            </TabPanel>

            <TabPanel value="2">
              <DailyAnalyticsTable />
            </TabPanel>
          </TabWrapper>
        </TabContext>
      </Modal>
    </Box>
  )
}

export default Participants
