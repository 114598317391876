export type Paginate<T> = Array<T>

export const paginate = <T>(
    data: Array<T>,
        pageSize: number,
        pageNumber: number
        ): Paginate<T> | undefined => {
  const start = (pageNumber - 1) * pageSize
            const pageData = data.slice(start, start + pageSize)

  return pageData.length > 0 ? pageData : undefined
}