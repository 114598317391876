import { type ReactNode, useState } from "react"

import { Alert } from "@mui/material"
import {
  GeoGuessrContext,
  SETTINGS_DEFAULT_VALUES,
  type GeoGuessrSettings,
  type GeoGuessrTab,
} from "contexts/geoguessr"
import { useTranslation } from "react-i18next"
import { useEffectOnce } from "react-use"
import { DRAFT_MODE_SECRET, fetchJsonDump, saveJsonDump } from "services/Taiyoro/jsonDump"
import { merge } from "ts-deepmerge"
import { openLinkInNewTab } from "utils/tools"

export const GEOGUESSR_SETTINGS_KEY = "geoguessr"

export const DataProvider = ({ children }: { children: ReactNode }) => {
  const { t, i18n } = useTranslation()

  const [tab, setTab] = useState<GeoGuessrTab>("news")
  const [settings, setSettings] = useState<GeoGuessrSettings>(SETTINGS_DEFAULT_VALUES)
  const [modifiedSettings, setModifiedSettings] = useState<GeoGuessrSettings>(SETTINGS_DEFAULT_VALUES)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const fetchStoredSettings = () =>
    fetchJsonDump(GEOGUESSR_SETTINGS_KEY).then((res) => {
      // NULL is an expected result. It means the data has never been set before
      if (res === null) return
      const parsedResults = JSON.parse(res) as GeoGuessrSettings
      const mergedResults = merge(SETTINGS_DEFAULT_VALUES, parsedResults)
      setSettings(mergedResults)
      // Intentionally using object reference to understand if there's no changes by object comparison
      // All calls to setModifiedResults should destructure the object and break referencial equality
      setModifiedSettings(mergedResults)
    })

  const load = () => {
    setLoading(true)
    Promise.all([fetchStoredSettings()])
      .catch(() => setError(true))
      .finally(() => setLoading(false))
  }

  const save = () => {
    setLoading(true)
    const payload = {
      id: GEOGUESSR_SETTINGS_KEY,
      type: "GeoGuessr",
      data: JSON.stringify(modifiedSettings),
    }
    saveJsonDump(payload, "SYSTEM")
      .then(() => {
        setSettings(modifiedSettings)
      })
      .catch(() => setError(error))
      .finally(() => setLoading(false))
  }

  const viewDraft = () => {
    setLoading(true)
    saveJsonDump({ type: "GeoGuessr", data: JSON.stringify(modifiedSettings) })
      .then((draftId) => {
        const url = new URL(
          (process.env.REACT_APP_TAIYORO_DOMAIN
            ? `${process.env.REACT_APP_TAIYORO_DOMAIN}/new`
            : "http://localhost:3000/new") + "/api/draft"
        )
        const slug = `/${i18n.language}/geoguessr`
        url.searchParams.append("slug", slug)
        url.searchParams.append("secret", DRAFT_MODE_SECRET)
        url.searchParams.append("draftId", draftId)
        openLinkInNewTab(url.toString())
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false))
  }

  useEffectOnce(() => {
    load()
  })

  return (
    <GeoGuessrContext.Provider
      value={{
        tab,
        setTab,
        settings,
        save,
        viewDraft,
        modifiedSettings,
        setModifiedSettings,
        loading,
      }}
    >
      {error && <Alert severity="error">{t("common:errorLoadingData")}</Alert>}
      {children}
    </GeoGuessrContext.Provider>
  )
}
