import React, { useEffect, useRef, useState, useContext, useCallback } from "react"

import { faClone, faPlusCircle, faTrashAlt, faClockRotateLeft } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, Grid, IconButton, Paper, Stack, Table, Tooltip, Typography } from "@mui/material"
import { withStyles } from "@mui/styles"
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog"
import { DataRecoveryContext } from "contexts/data-recovery"
import { EventContext } from "contexts/event"
import { useTranslation } from "react-i18next"

import type Game from "../../../../../models/Taiyoro/Meta/Game"
import type Platform from "../../../../../models/Taiyoro/Meta/Platform"
import type Venue from "../../../../../models/Taiyoro/Meta/Venue"
import EventStreamModel, { RecoveryStatus } from "../../../../../models/Taiyoro/Stream"
import { PublishedState } from "../../../../../models/Taiyoro/event"
import EventDateModel from "../../../../../models/Taiyoro/eventDate"
import { UserRole } from "../../../../../models/UserManagement"
import { editDate, saveDatePlatform } from "../../../../../services/Taiyoro/event"
import { HIGH_ACCESS_ROLES } from "../../../../../utils/roles"
import useRolesCanAccess from "../../../../../utils/useRolesCanAccess"
import { space } from "../../../../Styles/vars"
import TableList from "../../../../Table/TableList"
import RealTimeUpdateField from "../../../RealTimeUpdateField"
import DateTimings from "./DateTimings"
import EventStream from "./Streams/Stream"
import EventVOD from "./Streams/VOD"
import { Root } from "./styles"

// (Roy) Should maybe be a database controlled backend field?
const platformsCapableOfAutoGeneration = ["openrec", "twitch", "youtube"]

const AddStreamButton = withStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(Button)

const AddVODButton = withStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(Button)

interface Props {
  eventPublishedState: PublishedState
  model: EventDateModel
  onDelete: (id: string | null) => void
  onDeleteStream: (id: string | null) => void
  onUpdate: (changes: any) => void
  onDuplicate: (model: EventDateModel) => void
  availablePlatforms: Platform[]
  availableGames: Game[]
  availableVenues: Venue[]
}

const EventDate = ({
  eventPublishedState,
  model,
  onDelete,
  onDeleteStream,
  onUpdate,
  onDuplicate,
  availablePlatforms,
  availableGames,
  availableVenues,
}: Props) => {
  const ref = useRef(null)

  // eslint-disable-next-line
  const [idState] = useState(model.id || null)
  const [nameState, setNameState] = useState(model.name || "")
  const [startTimeState, setStartTimeState] = useState(model.startTime || null)
  const [endTimeState, setEndTimeState] = useState(model.endTime || null)
  const [estimatedState, setEstimatedState] = useState(model.estimated || 0)
  const [cancelledState, setCancelledState] = useState(model.cancelled || 0)
  const [hiddenState, setHiddenState] = useState(model.hidden || 0)
  const [venueState, setVenueState] = useState(model.venue || "")
  const [offlineState, setOfflineState] = useState(model.offline || 0)
  const [attendeesState, setAttendeesState] = useState(model.attendees)
  const [gameState, setGameState] = useState(model.game || "")
  const [ticketState, setTicketState] = useState(model.ticket)

  const [event, setEvent] = useContext(EventContext)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [showDuplicateConfirmation, setShowDuplicateConfirmation] = useState(false)

  // For Data Recovery Feature
  const { setSelectedEventDate } = useContext(DataRecoveryContext)

  const { t } = useTranslation(["taiyoro", "common"])

  const isHighAccessUser = useRolesCanAccess(HIGH_ACCESS_ROLES)

  const isEditableToEditor =
    useRolesCanAccess([UserRole.EDITOR]) && eventPublishedState === PublishedState.Draft

  const canDelete = isHighAccessUser || isEditableToEditor

  const isPlatformCapableOfAutoGeneration = (platformId: string) => {
    const platform = availablePlatforms.find((p) => p.id === platformId)
    return platform && platformsCapableOfAutoGeneration.some((p) => platform.name.toLowerCase().includes(p))
  }

  const handleDuplicate = useCallback(() => {
    const platforms = [
      ...model.platforms
        .filter((p) => p.isVod === 0)
        .map((stream) => {
          return {
            platform: stream.platform,
            url: stream.url,
            language: stream.language,
            isVod: stream.isVod,
            automaticallyGenerated: stream.automaticallyGenerated,
            automaticallyGenerateVods: stream.automaticallyGenerateVods,
            isSubStream: stream.isSubStream,
          }
        }),
      ...model.platforms
        .filter((p) => p.isVod === 1)
        .map((stream) => {
          return {
            platform: stream.platform,
            url: stream.url,
            language: stream.language,
            isVod: stream.isVod,
            automaticallyGenerated: stream.automaticallyGenerated,
            automaticallyGenerateVods: stream.automaticallyGenerateVods,
            isSubStream: stream.isSubStream,
          }
        }),
    ]
    onDuplicate(
      new EventDateModel(
        null,
        nameState,
        startTimeState,
        endTimeState,
        gameState,
        venueState,
        offlineState,
        attendeesState,
        platforms,
        estimatedState,
        cancelledState,
        hiddenState,
        ticketState
      )
    )
    setShowDuplicateConfirmation(false)
  }, [event])

  // Scrolls to the copied date
  useEffect(() => {
    if (model.id === null) {
      ref.current.scrollIntoView({ behavior: "smooth" })
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (cancelledState !== model.cancelled) {
      setCancelledState(model.cancelled)
    }
    // eslint-disable-next-line
  }, [model.cancelled])

  return (
    <Root
      container
      ref={ref}
    >
      <Paper
        sx={{ marginBottom: "12px", paddingBottom: "12px", width: "100%" }}
        elevation={2}
      >
        <Grid
          container
          sx={{ padding: "12px" }}
          spacing={2}
          direction="row-reverse"
        >
          <Grid item>
            <Grid
              container
              justifyItems="space-between"
              spacing={2}
            >
              <Grid item>
                <Box>
                  <RealTimeUpdateField
                    type="checkbox"
                    label={t("edit.dates.cancelled")}
                    updateFunc={(value) => {
                      const intValue = parseInt(value)
                      setCancelledState(cancelledState)
                      onUpdate({ cancelled: intValue })
                      return editDate(idState, { isCancelled: intValue })
                    }}
                    initialValue={cancelledState}
                  />
                </Box>
                <Box>
                  <RealTimeUpdateField
                    type="checkbox"
                    label={t("edit.dates.estimated")}
                    updateFunc={(value) => {
                      const intValue = parseInt(value)
                      setEstimatedState(intValue)
                      return editDate(idState, { isEstimated: intValue })
                    }}
                    initialValue={estimatedState}
                  />
                </Box>
                <Box>
                  <RealTimeUpdateField
                    type="checkbox"
                    label={t("edit.dates.hidden")}
                    updateFunc={(value) => {
                      const intValue = parseInt(value)
                      setHiddenState(intValue)
                      return editDate(idState, { isHidden: intValue })
                    }}
                    initialValue={hiddenState}
                  />
                </Box>
                <Box>
                  <RealTimeUpdateField
                    type="checkbox"
                    label={t("edit.dates.offline")}
                    updateFunc={(value) => {
                      const intValue = parseInt(value)
                      setOfflineState(intValue)
                      return editDate(idState, { isOffline: intValue })
                    }}
                    initialValue={offlineState}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Tooltip title={t("meta.controls.recoverData")}>
                  <IconButton
                    onClick={() => setSelectedEventDate(model)}
                    sx={{ width: "32px", height: "32px" }}
                  >
                    <FontAwesomeIcon icon={faClockRotateLeft} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("meta.controls.duplicate")}>
                  <IconButton
                    onClick={() => setShowDuplicateConfirmation(true)}
                    sx={{ width: "32px", height: "32px" }}
                  >
                    <FontAwesomeIcon icon={faClone} />
                  </IconButton>
                </Tooltip>
                {canDelete && (
                  <Tooltip title={t("common:actions.delete")}>
                    <IconButton
                      color="error"
                      onClick={() => setShowDeleteConfirmation(true)}
                      sx={{ width: "32px", height: "32px" }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            flexGrow="1"
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                sm={12}
                md={6}
              >
                <RealTimeUpdateField
                  type="text"
                  variant="standard"
                  label={t("edit.dates.name")}
                  updateFunc={(value) => {
                    setNameState(value)
                    return editDate(idState, { name: value })
                  }}
                  initialValue={nameState}
                />
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
              >
                {availableGames.length > 0 && availableGames.find((game) => game.id === gameState) && (
                  <RealTimeUpdateField
                    type="select"
                    variant="standard"
                    disableClearable
                    label={t("edit.dates.game")}
                    options={availableGames}
                    updateFunc={(value) => {
                      setGameState(value.id)
                      return editDate(idState, { gameId: value.id })
                    }}
                    initialValue={availableGames.find((game) => game.id === gameState)}
                  />
                )}
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              sx={{ marginTop: "12px", marginBottom: "12px" }}
            >
              <DateTimings
                dateId={idState}
                startTime={startTimeState}
                endTime={endTimeState}
                onUpdate={(startTime, endTime) => {
                  setStartTimeState(startTime)
                  setEndTimeState(endTime)
                  onUpdate({ startTime: startTime, endTime: endTime })
                }}
              />
            </Grid>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                sm={12}
              >
                <RealTimeUpdateField
                  type="number-format"
                  variant="standard"
                  label={t("edit.dates.attendees")}
                  updateFunc={(value) => {
                    const nullableValue = value ? parseInt(value) : null
                    setAttendeesState(nullableValue)
                    return editDate(idState, { attendees: nullableValue })
                  }}
                  initialValue={attendeesState}
                />
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
              >
                <RealTimeUpdateField
                  type="select"
                  variant="standard"
                  label={t("edit.dates.venue")}
                  options={availableVenues}
                  updateFunc={(value) => {
                    const nullableValue = value ? value.id : null
                    setVenueState(nullableValue)
                    return editDate(idState, { venueId: nullableValue })
                  }}
                  initialValue={availableVenues.find((venue) => venue.id === venueState)}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={12}
              >
                <RealTimeUpdateField
                  type="text"
                  variant="standard"
                  label={t("edit.dates.ticket")}
                  updateFunc={(value) => {
                    const nullableValue = value ?? null
                    setTicketState(nullableValue)
                    return editDate(idState, { ticket: nullableValue })
                  }}
                  initialValue={ticketState}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(10, 1fr)",
            gridTemplateRows: "repeat(3, 1fr)",
            gridColumnGap: "8px",
            gridRowGap: "8px",
            alignItems: "center",
          }}
          px={2}
        >
          <Typography
            variant="body2"
            sx={{ gridColumn: "span 2" }}
          >
            {t("edit.dates.streams.types.stream")}
          </Typography>

          <Typography
            variant="body2"
            sx={{ gridColumn: "span 8" }}
          >
            {t("edit.dates.streams.liveStats")}
          </Typography>
          {model.platforms
            .filter((s) => s.isVod === 0)
            .map((stream) => (
              <EventStream
                key={stream.id}
                model={stream}
                eventPublishedState={eventPublishedState}
                availablePlatforms={availablePlatforms}
                onUpdate={(changes) => {
                  setEvent((oldEvent) => {
                    const newEvent = Object.assign(Object.create(Object.getPrototypeOf(oldEvent)), oldEvent)
                    const newDateIndex = newEvent.dates.findIndex((date) => date.id === model.id)
                    if (newDateIndex === -1) {
                      console.error("Couldn't find date")
                      return newEvent
                    }
                    const newPlatformIndex = newEvent.dates[newDateIndex].platforms.findIndex(
                      (platform) => platform.id === stream.id
                    )
                    if (newPlatformIndex === -1) {
                      console.error("Couldn't find platform")
                      return newEvent
                    }

                    newEvent.dates[newDateIndex].platforms[newPlatformIndex] = {
                      ...newEvent.dates[newDateIndex].platforms[newPlatformIndex],
                      ...changes,
                    }
                    return newEvent
                  })
                }}
                onDelete={(id) => {
                  if (id !== null) {
                    onDeleteStream(id)
                  }
                  setEvent((oldEvent) => {
                    const newEvent = Object.assign(Object.create(Object.getPrototypeOf(oldEvent)), oldEvent)
                    const newDate = newEvent.dates.find((date) => date.id === model.id)
                    newDate.platforms = newDate.platforms.filter((platform) => platform.id !== id)
                    return newEvent
                  })
                }}
              />
            ))}
          <Box sx={{ gridColumn: "1 / 2" }}>
            <AddStreamButton
              onClick={() => {
                const platformId =
                  availablePlatforms && availablePlatforms.length > 0 && availablePlatforms[0].id
                const isPlatformCapableOfAutoGenerationValue = isPlatformCapableOfAutoGeneration(platformId)
                  ? 1
                  : 0
                const newModel = new EventStreamModel(
                  null,
                  platformId,
                  "https://",
                  0,
                  "ja",
                  1,
                  RecoveryStatus.NOT_SET,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  isPlatformCapableOfAutoGenerationValue,
                  0,
                  0
                )
                saveDatePlatform(idState, newModel).then((response) => {
                  if (response) {
                    newModel.id = response.id
                    setEvent((oldEvent) => {
                      const newEvent = Object.assign(Object.create(Object.getPrototypeOf(oldEvent)), oldEvent)
                      const newDate = newEvent.dates.find((date) => date.id === model.id)
                      if (newDate.platforms) {
                        newDate.platforms.push(newModel)
                      } else {
                        newDate.platforms = [newModel]
                      }
                      return newEvent
                    })
                  }
                })
              }}
            >
              <FontAwesomeIcon icon={faPlusCircle} />
              <span style={{ paddingLeft: "8px" }}>{t("edit.dates.streams.addStream")}</span>
            </AddStreamButton>
          </Box>

          <Typography variant="body2">{t("edit.dates.streams.total")}</Typography>
          <Typography
            variant="body2"
            textAlign="right"
          >
            {Number(
              model?.platforms
                .filter((p) => p.isVod === 0)
                .reduce(function (p, c) {
                  return c.viewsLiveConcurrentPeakManual || c.viewsLiveConcurrentPeak
                    ? p + (c.viewsLiveConcurrentPeakManual || c.viewsLiveConcurrentPeak)
                    : p
                }, 0)
            ).toLocaleString()}
          </Typography>
          <Typography
            variant="body2"
            textAlign="right"
          >
            {Number(
              model?.platforms
                .filter((p) => p.isVod === 0)
                .reduce(function (p, c) {
                  return c.viewsLiveConcurrentAverageManual || c.viewsLiveConcurrentAverage
                    ? p + (c.viewsLiveConcurrentAverageManual || c.viewsLiveConcurrentAverage)
                    : p
                }, 0)
            ).toLocaleString()}
          </Typography>
          <Typography
            variant="body2"
            textAlign="right"
          >
            {Number(
              model?.platforms
                .filter((p) => p.isVod === 0)
                .reduce(function (p, c) {
                  return c.viewsLiveMinutesWatchedManual || c.viewsLiveMinutesWatched
                    ? p + (c.viewsLiveMinutesWatchedManual || c.viewsLiveMinutesWatched)
                    : p
                }, 0)
            ).toLocaleString()}
          </Typography>
          <Box sx={{ gridColumn: "6 / 11" }} />
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(10, 1fr)",
            gridTemplateRows: "repeat(3, 1fr)",
            gridColumnGap: "8px",
            gridRowGap: "8px",
            alignItems: "center",
          }}
          px={2}
        >
          <Typography
            variant="body2"
            sx={{ gridColumn: "span 2" }}
          >
            {t("edit.dates.streams.types.vod")}
          </Typography>
          <Typography
            variant="body2"
            sx={{ gridColumn: "span 8" }}
          >
            {t("edit.dates.streams.vodStats")}
          </Typography>
          {model.platforms
            .filter((platform) => platform.isVod === 1)
            .map((vod) => (
              <EventVOD
                eventPublishedState={eventPublishedState}
                key={`event-stream-${vod.id}`}
                model={vod}
                availablePlatforms={availablePlatforms}
                onUpdate={(changes) => {
                  setEvent((oldEvent) => {
                    const newEvent = Object.assign(Object.create(Object.getPrototypeOf(oldEvent)), oldEvent)
                    const newDateIndex = newEvent.dates.findIndex((date) => date.id === model.id)
                    if (newDateIndex === -1) {
                      console.error("Couldn't find date")
                      return newEvent
                    }
                    const newPlatformIndex = newEvent.dates[newDateIndex].platforms.findIndex(
                      (platform) => platform.id === vod.id
                    )
                    if (newPlatformIndex === -1) {
                      console.error("Couldn't find platform")
                      return newEvent
                    }

                    newEvent.dates[newDateIndex].platforms[newPlatformIndex] = {
                      ...newEvent.dates[newDateIndex].platforms[newPlatformIndex],
                      ...changes,
                    }
                    return newEvent
                  })
                }}
                onDelete={(id) => {
                  if (id !== null) {
                    onDeleteStream(id)
                  }
                  setEvent((oldEvent) => {
                    const newEvent = Object.assign(Object.create(Object.getPrototypeOf(oldEvent)), oldEvent)
                    const newDate = newEvent.dates.find((date) => date.id === model.id)
                    newDate.platforms = newDate.platforms.filter((platform) => platform.id !== id)
                    return newEvent
                  })
                }}
              />
            ))}
          <Box>
            <AddVODButton
              onClick={() => {
                const newModel = new EventStreamModel(
                  null,
                  availablePlatforms && availablePlatforms.length > 0 && availablePlatforms[0].id,
                  "https://",
                  1,
                  "ja",
                  1,
                  RecoveryStatus.NOT_SET
                )
                saveDatePlatform(idState, newModel).then((response) => {
                  if (response) {
                    newModel.id = response.id
                    setEvent((oldEvent) => {
                      const newEvent = Object.assign(Object.create(Object.getPrototypeOf(oldEvent)), oldEvent)
                      const newDate = newEvent.dates.find((date) => date.id === model.id)
                      if (newDate.platforms) {
                        newDate.platforms = [...newDate.platforms, newModel]
                      } else {
                        newDate.platforms = [newModel]
                      }
                      return newEvent
                    })
                  }
                })
              }}
            >
              <FontAwesomeIcon icon={faPlusCircle} />
              <span style={{ paddingLeft: "8px" }}>{t("edit.dates.streams.addVOD")}</span>
            </AddVODButton>
          </Box>
          <Typography variant="body2">{t("edit.dates.streams.total")}</Typography>
          <Typography
            variant="body2"
            textAlign="right"
          >
            {Number(
              model.platforms
                .filter((platform) => platform.isVod === 1)
                .reduce(function (p, c) {
                  return c.views1WeekManual || c.views1Week ? p + (c.views1WeekManual || c.views1Week) : p
                }, 0)
            ).toLocaleString()}
          </Typography>
          <Typography
            variant="body2"
            textAlign="right"
          >
            {Number(
              model.platforms
                .filter((platform) => platform.isVod === 1)
                .reduce(function (p, c) {
                  return c.viewsWeek2Manual || c.viewsWeek2 ? p + (c.viewsWeek2Manual || c.viewsWeek2) : p
                }, 0)
            ).toLocaleString()}
          </Typography>
          <Typography
            variant="body2"
            textAlign="right"
          >
            {Number(
              model.platforms
                .filter((platform) => platform.isVod === 1)
                .reduce(function (p, c) {
                  return c.viewsWeek3Manual || c.viewsWeek3 ? p + (c.viewsWeek3Manual || c.viewsWeek3) : p
                }, 0)
            ).toLocaleString()}
          </Typography>
          <Typography
            variant="body2"
            textAlign="right"
          >
            {Number(
              model.platforms
                .filter((platform) => platform.isVod === 1)
                .reduce(function (p, c) {
                  return c.viewsWeek4Manual || c.viewsWeek4 ? p + (c.viewsWeek4Manual || c.viewsWeek4) : p
                }, 0)
            ).toLocaleString()}
          </Typography>
          <Box sx={{ gridColumn: "span 4" }} />
        </Box>
      </Paper>
      <ConfirmationDialog
        open={showDeleteConfirmation}
        title={t("edit.dates.delete.title", { date: model.name })}
        body={t("common:actions.checkAgain")}
        onConfirm={() => onDelete(idState)}
        onCancel={() => setShowDeleteConfirmation(false)}
        confirmText={t("common:actions.delete")}
        cancelText={t("common:actions.cancel")}
      />
      <ConfirmationDialog
        open={showDuplicateConfirmation}
        title={t("edit.dates.duplicate.title", { date: model.name })}
        onConfirm={handleDuplicate}
        onCancel={() => setShowDuplicateConfirmation(false)}
        confirmText={t("edit.dates.duplicate.action")}
        cancelText={t("common:actions.cancel")}
      />
    </Root>
  )
}

export default EventDate
