import { useContext, useState } from "react"

import { faEye, faPlus, faSave } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Stack, Tab, Tabs } from "@mui/material"
import { type PostMinimumData } from "components/Taiyoro/LJL/2025/EventResultContents/news"
import { PostSelectionDialog } from "components/Taiyoro/LJL/2025/EventResultContents/post-selection-dialog"
import { GeoGuessrContext, type GeoGuessrTab } from "contexts/geoguessr"
import type Event from "models/Taiyoro/event"
import { useTranslation } from "react-i18next"

import { EventSelectionDialog } from "../EventSelectionDialog"

const tabs: Array<GeoGuessrTab> = ["news", "events"]

export const ControlBar = () => {
  const [newsAddDialogOpen, setNewsAddDialogOpen] = useState(false)
  const [eventsAddDialogOpen, setEventsAddDialogOpen] = useState(false)
  const { t } = useTranslation("common")
  const { tab, setTab, save, viewDraft, loading, settings, modifiedSettings, setModifiedSettings } =
    useContext(GeoGuessrContext)

  const addNewsEntry = (post: PostMinimumData) => {
    setModifiedSettings((previousModifiedResults) => {
      const newModifiedResults = { ...previousModifiedResults }
      newModifiedResults.news = [post, ...newModifiedResults.news]
      return newModifiedResults
    })
    setNewsAddDialogOpen(false)
  }

  const addEventsEntry = (event: Event) => {
    const { id, name } = event
    setModifiedSettings((previousModifiedResults) => {
      const newModifiedResults = { ...previousModifiedResults }
      newModifiedResults.events = [{ id, name }, ...newModifiedResults.events]
      return newModifiedResults
    })
    setEventsAddDialogOpen(false)
  }

  const handleAddEntry = () => {
    switch (tab) {
      case "news":
        setNewsAddDialogOpen(true)
        break
      case "events":
        setEventsAddDialogOpen(true)
        break
      default:
        return
    }
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="baseline"
      >
        <Tabs
          value={tab}
          onChange={(_event, value) => setTab(value as GeoGuessrTab)}
        >
          {tabs.map((tab) => (
            <Tab
              value={tab}
              key={tab}
              label={tab}
              disabled={loading}
            />
          ))}
        </Tabs>
        <Stack
          gap={2}
          direction="row"
        >
          <Button
            size="medium"
            variant="contained"
            color="success"
            aria-label="add"
            onClick={handleAddEntry}
            sx={{
              flexShrink: 0,
            }}
          >
            {t("actions.add")}
            <FontAwesomeIcon
              icon={faPlus}
              style={{ marginLeft: "8px" }}
            />
          </Button>
          <Button
            size="medium"
            variant="contained"
            color="primary"
            aria-label="preview"
            onClick={viewDraft}
            disabled={loading}
            sx={{
              flexShrink: 0,
            }}
          >
            {t("actions.preview")}
            <FontAwesomeIcon
              icon={faEye}
              style={{ marginLeft: "8px" }}
            />
          </Button>
          <Button
            size="medium"
            variant="contained"
            color="primary"
            aria-label="save"
            disabled={loading || settings === modifiedSettings}
            onClick={save}
            sx={{
              flexShrink: 0,
            }}
          >
            {t("actions.save")}
            <FontAwesomeIcon
              icon={faSave}
              style={{ marginLeft: "8px" }}
            />
          </Button>
        </Stack>
      </Stack>
      <PostSelectionDialog
        open={newsAddDialogOpen}
        onClose={() => setNewsAddDialogOpen(false)}
        onPostSelected={addNewsEntry}
      />
      <EventSelectionDialog
        open={eventsAddDialogOpen}
        onClose={() => setEventsAddDialogOpen(false)}
        onEventSelected={addEventsEntry}
      />
    </>
  )
}
