import { faArrowDown, faFilter } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Stack,
  Link,
  IconButton,
  Tooltip,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { ADMIN_ROLES } from "utils/roles"
import useRolesCanAccess from "utils/useRolesCanAccess"

import { RankedDataSort } from ".."
import type { RankedStreamResult } from "../../../../models/Scouting"
import type Game from "../../../../models/Taiyoro/Meta/Game"
import { LocalMomentFromUTCDateTime } from "../../../../models/Taiyoro/eventDate"
import { localisedLabel } from "../../../../utils/i18n"
import { SortArrow } from "../../../Taiyoro/Analytics/Table/styles"

interface Props {
  games: Array<Game>
  results: Array<RankedStreamResult>
  sort: RankedDataSort
  onSortChange: (sort: RankedDataSort) => void
  onAddToIgnoreList: (result: RankedStreamResult) => void
}

const RankedStreamResults = (props: Props) => {
  const { t } = useTranslation(["scouting", "taiyoro", "data-collection"])

  const isAdmin = useRolesCanAccess(ADMIN_ROLES)

  return (
    <Stack spacing={1}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography noWrap>{t("data-collection:explore.table.userName")}</Typography>
            </TableCell>
            <TableCell>
              <Typography noWrap>{t("taiyoro:analytics.results.GAME_NAME")}</Typography>
            </TableCell>
            <TableCell>
              <Typography noWrap>{t("taiyoro:analytics.results.PLATFORM_GAME_NAME")}</Typography>
            </TableCell>
            <TableCell>
              <Typography noWrap>{t("data-collection:explore.table.streamTitle")}</Typography>
            </TableCell>
            <TableCell>
              <Typography noWrap>{t("taiyoro:analytics.results.PLATFORM_NAME")}</Typography>
            </TableCell>
            <TableCell>
              <Typography noWrap>{t("data-collection:explore.table.videoUrl")}</Typography>
            </TableCell>
            <TableCell>
              <Typography noWrap>{t("taiyoro:analytics.results.EVENT_START")}</Typography>
            </TableCell>
            <TableCell>
              <Typography noWrap>{t("taiyoro:analytics.results.EVENT_END")}</Typography>
            </TableCell>
            <TableCell>
              <Typography
                noWrap
                sx={{ cursor: "pointer" }}
                onClick={() => props.onSortChange(RankedDataSort.PCU_DESC)}
              >
                {t("taiyoro:analytics.results.PEAK_CONCURRENTS")}
                {props.sort === RankedDataSort.PCU_DESC && (
                  <SortArrow>
                    <FontAwesomeIcon
                      color="#f30362"
                      icon={faArrowDown}
                    />
                  </SortArrow>
                )}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                noWrap
                sx={{ cursor: "pointer" }}
                onClick={() => props.onSortChange(RankedDataSort.ACU_DESC)}
              >
                {t("taiyoro:analytics.results.AVERAGE_CONCURRENTS")}
                {props.sort === RankedDataSort.ACU_DESC && (
                  <SortArrow>
                    <FontAwesomeIcon
                      color="#f30362"
                      icon={faArrowDown}
                    />
                  </SortArrow>
                )}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                noWrap
                sx={{ cursor: "pointer" }}
                onClick={() => props.onSortChange(RankedDataSort.MINUTES_WATCHED_DESC)}
              >
                {t("taiyoro:analytics.results.MINUTES_WATCHED")}
                {props.sort === RankedDataSort.MINUTES_WATCHED_DESC && (
                  <SortArrow>
                    <FontAwesomeIcon
                      color="#f30362"
                      icon={faArrowDown}
                    />
                  </SortArrow>
                )}
              </Typography>
            </TableCell>
            {/* Empty cell for actions */}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {props.results.map((result) => (
            <TableRow
              key={result.id}
              data-id={result.id}
            >
              <TableCell>
                <Link
                  href={result.channelUrl}
                  target="_blank"
                >
                  {result.userName}
                </Link>
              </TableCell>
              <TableCell>
                {result.taiyoroGameId
                  ? localisedLabel(props.games.find((game) => game.id === result.taiyoroGameId))
                  : result.gameName}
              </TableCell>
              <TableCell>{result.platformGameName}</TableCell>
              <TableCell>{result.streamTitle}</TableCell>
              <TableCell>{result.platformName}</TableCell>
              <TableCell>
                <Link
                  href={result.videoUrl}
                  target="_blank"
                >
                  {result.videoUrl}
                </Link>
              </TableCell>
              <TableCell>
                {result.startDatetime &&
                  LocalMomentFromUTCDateTime(result.startDatetime).format("YYYY-MM-DD HH:mm:ss")}
              </TableCell>
              <TableCell>
                {result.endDatetime &&
                  LocalMomentFromUTCDateTime(result.endDatetime).format("YYYY-MM-DD HH:mm:ss")}
              </TableCell>
              <TableCell>{Number(result.viewsLiveConcurrentPeak).toLocaleString()}</TableCell>
              <TableCell>{Number(result.viewsLiveConcurrentAverage).toLocaleString()}</TableCell>
              <TableCell>{Number(result.viewsLiveMinutesWatched).toLocaleString()}</TableCell>
              <TableCell>
                {isAdmin && (
                  <Tooltip title={t("ignore")}>
                    <IconButton
                      onClick={() => props.onAddToIgnoreList(result)}
                      color="error"
                    >
                      <FontAwesomeIcon icon={faFilter} />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {props.results.length === 0 && <Typography>{t("noResults")}</Typography>}
    </Stack>
  )
}

export default RankedStreamResults
