import { useContext, useState } from "react"

import { faEye, faPlus, faSave } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Stack, Tab, Tabs } from "@mui/material"
import { type LJL2025Results, Ljl2025ResultsContext, type LJL2025Tab } from "contexts/ljl-2025-results"
import { useTranslation } from "react-i18next"

import { type PostMinimumData } from "../EventResultContents/news"
import { PostSelectionDialog } from "../EventResultContents/post-selection-dialog"

const tabs: Array<LJL2025Tab> = ["championship-points", "forge", "storm", "ignite", "finals", "news"]

export const ControlBar = () => {
  const [newsAddDialogOpen, setNewsAddDialogOpen] = useState(false)
  const { t } = useTranslation("common")
  const {
    forgeTeams,
    stormTeams,
    igniteTeams,
    tab,
    setTab,
    save,
    viewDraft,
    loading,
    savedResults,
    modifiedResults,
    setModifiedResults,
  } = useContext(Ljl2025ResultsContext)

  const addChampionshipPointEntry = () => {
    setModifiedResults((previousModifiedResults) => {
      const newModifiedResults = { ...previousModifiedResults }
      const newEntry = {
        id: crypto.randomUUID(),
        rank: "0",
        teamId: [...forgeTeams, ...igniteTeams, ...stormTeams][0].id,
        forge: "0",
        storm: "0",
        ignite: "0",
        total: "0",
      } as LJL2025Results["championship-points"][0]
      newModifiedResults["championship-points"] = [...newModifiedResults["championship-points"], newEntry]
      return newModifiedResults
    })
  }

  const addForgeEntry = () => {
    setModifiedResults((previousModifiedResults) => {
      const newModifiedResults = { ...previousModifiedResults }
      const newEntry = {
        id: crypto.randomUUID(),
        rank: "0",
        teamId: forgeTeams[0].id,
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
        record: "",
        knockoutRank: "",
        championshipPoints: "",
      } as LJL2025Results["forge"]["tableData"][0]
      newModifiedResults.forge.tableData = [...newModifiedResults.forge.tableData, newEntry]
      return newModifiedResults
    })
  }

  const addStormEntry = () => {
    setModifiedResults((previousModifiedResults) => {
      const newModifiedResults = { ...previousModifiedResults }
      const newEntry = {
        id: crypto.randomUUID(),
        rank: "0",
        teamId: stormTeams[0].id,
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
        6: null,
        7: null,
        8: null,
        9: null,
        stormPoints: "",
        knockoutRank: "",
        championshipPoints: "",
      } as LJL2025Results["storm"]["tableData"][0]
      newModifiedResults.storm.tableData = [...newModifiedResults.storm.tableData, newEntry]
      return newModifiedResults
    })
  }

  const addIgniteEntry = () => {
    setModifiedResults((previousModifiedResults) => {
      const newModifiedResults = { ...previousModifiedResults }
      const newEntry = {
        id: crypto.randomUUID(),
        rank: "0",
        teamId: igniteTeams[0].id,
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
        6: null,
        ignitePoints: "",
        knockoutRank: "",
        knockoutPoints: "",
        championshipPoints: "",
      } as LJL2025Results["ignite"]["tableData"][0]
      newModifiedResults.ignite.tableData = [...newModifiedResults.ignite.tableData, newEntry]
      return newModifiedResults
    })
  }

  const addNewsEntry = (post: PostMinimumData) => {
    setModifiedResults((previousModifiedResults) => {
      const newModifiedResults = { ...previousModifiedResults }
      newModifiedResults.news = [post, ...newModifiedResults.news]
      return newModifiedResults
    })
    setNewsAddDialogOpen(false)
  }

  const handleAddEntry = () => {
    switch (tab) {
      case "championship-points":
        addChampionshipPointEntry()
        break
      case "forge":
        addForgeEntry()
        break
      case "storm":
        addStormEntry()
        break
      case "ignite":
        addIgniteEntry()
        break
      case "news":
        setNewsAddDialogOpen(true)
        break
      default:
        return
    }
  }

  const isAddDisabled = () => {
    if (loading) return true
    switch (tab) {
      case "championship-points":
        return [...forgeTeams, ...stormTeams, ...igniteTeams].length === 0
      case "forge":
        return forgeTeams.length === 0
      case "storm":
        return stormTeams.length === 0
      case "ignite":
        return igniteTeams.length === 0
      case "news":
        return false
      default:
        return true
    }
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="baseline"
      >
        <Tabs
          value={tab}
          onChange={(_event, value) => setTab(value as LJL2025Tab)}
        >
          {tabs.map((tab) => (
            <Tab
              value={tab}
              key={tab}
              label={tab}
              disabled={loading}
            />
          ))}
        </Tabs>
        <Stack
          gap={2}
          direction="row"
        >
          <Button
            size="medium"
            variant="contained"
            color="success"
            aria-label="add"
            disabled={isAddDisabled()}
            onClick={handleAddEntry}
            sx={{
              flexShrink: 0,
            }}
          >
            {t("actions.add")}
            <FontAwesomeIcon
              icon={faPlus}
              style={{ marginLeft: "8px" }}
            />
          </Button>
          <Button
            size="medium"
            variant="contained"
            color="primary"
            aria-label="preview"
            onClick={viewDraft}
            disabled={loading}
            sx={{
              flexShrink: 0,
            }}
          >
            {t("actions.preview")}
            <FontAwesomeIcon
              icon={faEye}
              style={{ marginLeft: "8px" }}
            />
          </Button>
          <Button
            size="medium"
            variant="contained"
            color="primary"
            aria-label="save"
            disabled={loading || savedResults === modifiedResults}
            onClick={save}
            sx={{
              flexShrink: 0,
            }}
          >
            {t("actions.save")}
            <FontAwesomeIcon
              icon={faSave}
              style={{ marginLeft: "8px" }}
            />
          </Button>
        </Stack>
      </Stack>
      <PostSelectionDialog
        open={newsAddDialogOpen}
        onClose={() => setNewsAddDialogOpen(false)}
        onPostSelected={addNewsEntry}
      />
    </>
  )
}
