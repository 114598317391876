import React, { useContext } from "react"

import { Box, Typography } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import type { GridColDef, GridColumns } from "@mui/x-data-grid"
import { FrontEndPagination } from "components/FrontEndPagination"
import { PickemSettingsContext } from "contexts/pickem"
import { useTranslation } from "react-i18next"
import type { PickemUser } from "services/Taiyoro/Pickem/participants"

export const ParticipantsTable = () => {
  const { t } = useTranslation("taiyoro")
  const {
    participants,
    paginateNumberOfRecords,
    setPaginateNumberOfRecords,
    paginatePage,
    setPaginatePage,
    paginatedListData,
    setPaginatedListData,
  } = useContext(PickemSettingsContext)

  const renderCells: Record<string, GridColDef<PickemUser>["renderCell"]> = {
    default: (params) => {
      return <Typography>{params.value}</Typography>
    },
    accuracy: (params) => {
      return <Typography>{Math.floor(params.value * 100) / 100}</Typography>
    },
    participation: (params) => {
      return <Typography>{Math.floor(params.value * 100) / 100}</Typography>
    },
  }

  const columns: GridColumns<PickemUser> = Object.keys(
    t("eventPickem.participants.tableHeaders", { returnObjects: true })
  ).map((key) => ({
    flex: 1,
    field: key,
    sortable: true,
    headerName: t(`eventPickem.participants.tableHeaders.${key}`),
    renderCell: renderCells[key] || renderCells["default"],
  }))

  return (
    <Box
      sx={{ width: "100%", height: "100%" }}
      mt={2}
    >
      <DataGrid
        sx={{ height: "calc(100% - 85px)" }}
        columns={columns}
        rows={paginatedListData}
        disableVirtualization
        autoHeight={true}
        rowHeight={100}
        disableColumnSelector
        disableColumnFilter
        disableColumnMenu
        hideFooter
        density="comfortable"
      />

      <FrontEndPagination
        listData={participants}
        paginateNumberOfRecords={paginateNumberOfRecords}
        setPaginateNumberOfRecords={setPaginateNumberOfRecords}
        paginatePage={paginatePage}
        setPaginatePage={setPaginatePage}
        setPaginatedListData={setPaginatedListData}
      />
    </Box>
  )
}
